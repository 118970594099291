<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9 mb-2">
                <div class="card">
                    <h4 class="card-header mb-0 justify-content-center">Contact</h4>
                    <div class="card-body">
                        <div>
                            <p class="card-text text-center">
                                Please fill out the form below and we will get back to you ASAP.
                            </p>
                            <form @submit.prevent="onSubmit" @keydown="form.clearErrors($event.target.name)">
                                <div class="form-group" :class="{ 'has-danger': form.errors.get('name') }">
                                    <label for="name">Name *</label>
                                    <input
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('name') }"
                                        v-model="form.name"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('name')"
                                        v-text="form.errors.get('name')"
                                    ></span>
                                </div>
                                <div class="form-group" :class="{ 'has-danger': form.errors.get('email') }">
                                    <label for="name">Email *</label>
                                    <input
                                        type="text"
                                        name="email"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('email') }"
                                        v-model="form.email"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('email')"
                                        v-text="form.errors.get('email')"
                                    ></span>
                                </div>
                                <div class="form-group" :class="{ 'has-danger': form.errors.get('phone') }">
                                    <label for="name">Phone Number</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('phone') }"
                                        v-model="form.phone"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('phone')"
                                        v-text="form.errors.get('phone')"
                                    ></span>
                                </div>
                                <div class="form-group">
                                    <label for="exampleSelect1">Inquiry Type *</label>
                                    <select class="form-control" id="exampleSelect1" v-model="form.inquiry_type">
                                        <option v-for="type in inquiry">
                                            {{ type }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group" :class="{ 'has-danger': form.errors.get('message') }">
                                    <label for="name">Message *</label>
                                    <textarea
                                        v-model="form.message"
                                        type="text"
                                        name="message"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('message') }"
                                    ></textarea>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('message')"
                                        v-text="form.errors.get('message')"
                                    ></span>
                                </div>
                                <div class="form-group">
                                    <input type="checkbox" @click="form.carbon_copy = !form.carbon_copy" />
                                    <label for="carbon_copy"
                                        >Check this box if you want a carbon copy of this message sent to your
                                        email.</label
                                    >
                                </div>
                                <div class="form-group row">
                                    <div class="col-4 mx-auto">
                                        <btn-state :state="form.state" btnClass="btn-block btn-primary">
                                            Send
                                        </btn-state>
                                    </div>
                                </div>

                                <div class="alert alert-success text-center" v-if="form.state === 'success'">
                                    <span>Message sent to admin.</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import news from '../../components/news.vue';
import btnState from '../../components/btnState.vue';
import Form from '../../form/form.js';

export default {
    components: { btnState, news },
    data() {
        return {
            inquiry: [
                'Advertising',
                'Add Venue',
                'Add (Not A Venue)',
                'Ask A Question',
                'Edit Venue',
                'Make A Comment',
                'Other',
                'Pro Services',
                'Report Site Problem',
                'Report Spam',
                'Suggest A Blog',
            ],
            form: new Form({
                name: window.User.full_name || '',
                email: window.User.email || '',
                phone: null,
                inquiry_type: 'Ask A Question',
                message: '',
                carbon_copy: false,
            }),
        };
    },
    created() {
        bus.$on('addVenue', () => {
            window.location.assign('/contact/add-venue');
        });
        if (location.search === '?type=spam') {
            this.form.message = document.referrer;
            this.form.inquiry_type = 'Report Spam';
        }
        if (location.search === '?suggest-blog') {
            this.form.inquiry_type = 'Suggest A Blog';
        }
        if (location.search === '?type=edit-venue') {
            this.form.message = document.referrer;
            this.form.inquiry_type = 'Edit Venue';
        }
        if (location.search === '?type=add-college') {
            this.form.inquiry_type = 'Add (Not A Venue)';
        }
        if (location.search === '?type=pro-services') {
            this.form.inquiry_type = 'Pro Services';
        }
    },
    computed: {
        contact_reason: function () {
            return this.form.inquiry_type;
        },
    },
    watch: {
        contact_reason: function () {
            if (this.contact_reason === 'Add Venue') {
                bus.$emit('addVenue');
            }
        },
    },
    methods: {
        onSubmit() {
            var $v = this;
            this.form
                .post('/contact')
                .then(function (response) {
                    $v.response = response.data;
                })
                .catch(function (error) {
                    $v.response = error.data;
                });
        },
    },
};
</script>
