<template>
    <div class="card">
        <div class="card-header bg-dark text-light">
            <h4 class="mb-0">{{ filter }} Bands</h4>
        </div>

        <div class="card-body">
            <table v-if="!loading" class="table">
                <tbody class="bg-white">
                    <tr v-for="(user, index) in items">
                        <td :class="index === 0 ? 'pt-0 border-0 row' : 'row'">
                            <div class="col-auto">
                                <img v-if="user.img_url" class="img-fluid" :src="user.img_url + '?w=100&h=100&fit=crop'" />
                                <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                            </div>
                            <div class="col">
                                <h6>
                                    <a class="break-word" :href="'/users/' + user.username">
                                        {{ user.display_name | truncate(100, '...') }}
                                    </a>
                                </h6>
                                <p class="mb-0">{{ user.follower_count }} followers</p>
                                <p class="mb-0">
                                    {{ user | cityState }}
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-info" v-show="!loading && items.length === 0">No {{ filter }} Bands</div>

            <loading v-show="loading"></loading>

            <div class="d-flex justify-content-center">
                <a href="/users?type=band" class="btn btn-outline-primary">All Bands</a>
            </div>
        </div>
    </div>
</template>

<script>
import starRating from '../rating/rating.vue';
import loading from '../loading.vue';

export default {
    props: ['filter'],

    components: { starRating, loading },

    data() {
        return {
            loading: true,
            items: [],
        };
    },

    computed: {
        query() {
            const sort = this.filter === 'Local Popular' ? 'follower_count' : 'created_at';
            const query = '?limit=3&type=band&sort_by=' + sort + '&sort_order=descending&include=genres';
            if (this.filter === 'National' || !window.User.postal_code) {
                return query;
            }

            return query + '&zip=' + window.User.postal_code + '&radius=100';
        },
    },

    watch: {
        filter: function () {
            this.fetchItems();
        },
    },

    created() {
        this.fetchItems();
    },

    methods: {
        fetchItems() {
            axios.get('/api/users' + this.query).then(response => {
                this.pagination = response.data.meta.pagination;
                this.items = response.data.data;
                this.loading = false;
            });
        },
    },
};
</script>
