<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="container">
                    <h4 class="m-0">
                        IOTM Address Book
                        <span class="small muted" v-show="pagination.total">({{ pagination.total }})</span>
                    </h4>
                </div>
            </div>

            <div class="card-body">
                <user-filters :showDistance="showDistance" :query="query" @toggleOrder="toggleOrder"
                    @queryUpdate="handleQueryUpdate">
                    <div slot="map">
                        <a @click="showingMap = !showingMap" class="btn btn-secondary d-flex align-items-center">
                            <i class="fa fa-map-marker mr-1"></i>
                            <span v-show="showingMap">Hide</span> Map
                        </a>
                    </div>

                    <div slot="submit">
                        <a @click="resetFilters" class="btn btn-secondary"> Reset </a>
                        <button :disabled="query.q.length > 0 && query.q.length < 2" @click="fetchUsers"
                            class="btn btn-primary text-white ml-1">
                            Search
                        </button>
                    </div>
                </user-filters>
            </div>
        </div>

        <user-map class="my-1" @closeMap="showingMap = false" :visible="showingMap" :items="users"></user-map>

        <user-results class="mt-1 mb-3" @sortBy="sortBy" :query="query" :showDistance="showDistance" :users="users"
            :loading="initialLoading"></user-results>

        <pagination :pagination="pagination" :loading="initialLoading" @pageChange="updatePage"></pagination>
    </div>
</template>

<script>
/* global location */
import Pagination from '../../components/pagination.vue';
import UserMap from '../../components/maps/map.vue';
import UserResults from '../../components/users/results.vue';
import UserFilters from '../../components/users/filters.vue';
import Query from '../../query/query.js';

export default {
    components: { Pagination, UserMap, UserResults, UserFilters },
    data() {
        return {
            showingMap: false,
            initialLoading: true,
            users: [],
            pagination: {
                current_page: 1,
            },
            query: new Query({
                q: '',
                sort_by: 'follower_count',
                sort_order: 'descending',
                country: '',
                state: '',
                city: '',
                zip: '',
                radius: '',
                genres: [],
                genre: '',
                type: '',
                include: 'genres',
                page: '',
                only_show_followed: false,
            }),
        };
    },

    created: function () {
        window.onpopstate = event => {
            this.query.parse(event.target.location.search);
            this.fetchUsers();
        };

        if (location.search) {
            this.query.parse(location.search);
        }

        this.fetchUsers();
    },
    computed: {
        fetchUrl() {
            return '/api/users?' + this.query.toString();
        },

        showDistance() {
            return Boolean(this.users[0] && this.users[0].distance);
        },
    },
    methods: {
        resetPagination() {
            this.pagination.current_page = 1;
            this.pagination.total_pages = 1;
        },

        resetFilters() {
            this.query.reset();
            this.handleQueryUpdate();
            bus.$emit('reset');
        },

        fetchUsers() {
            this.initialLoading = true;
            axios
                .get(this.fetchUrl)
                .then(response => {
                    this.pagination = response.data.meta.pagination;
                    this.users = response.data.data;
                    this.initialLoading = false;
                })
                .catch(error => {
                    this.initialLoading = false;
                    this.users = [];
                    this.resetPagination();
                    bus.$emit('error', error.response.data);
                });
        },

        handleQueryUpdate(pageChange = false) {
            if (!pageChange) {
                this.query.page = '';
                this.pagination.current_page = 1;
            }

            this.query.push();

            this.fetchUsers();
        },

        updatePage(number) {
            this.query.page = number;
            this.pagination.current_page = number;
            this.handleQueryUpdate(true);
        },

        sortBy(field) {
            this.query.sort_by = field;
            this.toggleOrder();
        },

        setOrder(order) {
            this.query.sort_order = order;
            this.handleQueryUpdate();
        },

        toggleOrder() {
            if (this.query.sort_order === 'ascending') {
                this.setOrder('descending');
            } else {
                this.setOrder('ascending');
            }
        },
    },
};
</script>
