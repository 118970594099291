<template>
    <div class="card">
        <div
            :class="dark ? 'bg-dark text-light' : ''"
            class="card-header d-flex justify-content-between align-items-center"
        >
            <h4 class="mb-0">Videos {{ title ? 'for ' + title : '' }}</h4>
            <a class="btn btn-sm btn-primary" :href="'/videos/create?venue=' + venue_uuid" v-if="venue_uuid">
                <i class="pr-2 fa fa-plus"></i>
                Add
            </a>

            <a v-else-if="isOwner" href="/media-videos" class="btn btn-primary btn-sm">
                <i class="fa fa-plus pr-2"></i>
                Add
            </a>
        </div>
        <div class="card-body">
            <div v-if="videos.length" class="text-center">
                <div class="flex-grid">
                    <img
                        class="flex-item-video"
                        v-for="(v, index) in videos"
                        v-if="v.thumbnail"
                        :key="v.uuid"
                        @click="viewVideo(index)"
                        :src="v.thumbnail + '?w=300&h=300&fit=crop'"
                    />
                </div>

                <a class="btn btn-link" v-show="moreLink" :href="moreLink">View All</a>
            </div>

            <div v-else-if="ready" class="text-center alert alert-info">No videos have been added.</div>

            <modal @close="activeVideoIndex = null" v-if="activeVideo">
                <div @click.stop slot="body">
                    <button class="close mb-2 btn btn-sm" @click="activeVideoIndex = null">
                        <i class="text-secondary fa fa-close"></i>
                    </button>

                    <div class="mb-2 embed-responsive embed-responsive-4by3 videoEmbed">
                        <iframe class="embed-responsive-item" :src="activeVideo.embed"></iframe>
                    </div>

                    <div class="text-center my-2">
                        <a class="btn btn-secondary btn-sm mr-2" @click.stop="viewVideo(activeVideoIndex - 1)">
                            <i class="fa fa-arrow-left"></i>
                            Prev
                        </a>
                        <a class="btn btn-secondary btn-sm" @click.stop="viewVideo(activeVideoIndex + 1)">
                            Next
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>

                    <p v-if="activeVideo.title"><b class="pr-2">Title:</b>{{ activeVideo.title }}</p>
                    <p v-if="activeVideo.venue">
                        <b class="pr-2">Venue:</b>
                        <a :href="'/venues/' + activeVideo.venue_slug"> {{ activeVideo.venue }}</a>
                    </p>
                    <p><b class="pr-2">Added:</b>{{ activeVideo.created_at | fromUTC('MMMM Do, YYYY') }}</p>
                    <p>
                        <b class="pr-2">By:</b>
                        <a :href="'/users/' + activeVideo.user">{{ activeVideo.user }}</a>
                    </p>
                    <p v-if="activeVideo.description"><b class="pr-2">Description:</b>{{ activeVideo.description }}</p>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import modal from '../modal.vue';

export default {
    name: 'videos',

    components: { modal },

    props: {
        user_uuid: {
            type: String,
        },
        venue_uuid: {
            type: String,
        },
        filter: {
            type: String,
        },
        title: {
            type: String,
        },
        dark: {
            type: Boolean,
            defaul: false,
        },
        limit: {
            default: false,
        },
    },

    data() {
        return {
            videos: [],
            activeVideoIndex: null,
            ready: false,
        };
    },
    created() {
        this.fetchVideos();
    },
    computed: {
        activeVideo() {
            if (this.activeVideoIndex >= 0) {
                return this.videos[this.activeVideoIndex];
            }
        },

        moreLink() {
            if (this.videos.length == this.limit && (this.user_uuid || this.venue_uuid)) {
                return window.location.href + '/videos';
            }
            return false;
        },

        isOwner() {
            return window.User && this.user_uuid && window.User.uuid === this.user_uuid;
        },

        fetchUrl() {
            if (this.venue_uuid) {
                return '/api/videos?venue=' + this.venue_uuid + (this.limit ? '&limit=' + this.limit : '');
            }
            if (this.user_uuid) {
                return '/api/videos?user=' + this.user_uuid + (this.limit ? '&limit=' + this.limit : '');
            }

            return '/api/videos' + this.homeFilter + (this.limit ? '&limit=' + this.limit : '');
        },

        homeFilter() {
            if (this.filter === 'Local New') {
                return '?radius=100';
            } else if (this.filter === 'Local Popular') {
                return '?radius=100&sort_by=views';
            }

            return '?';
        },
    },

    watch: {
        filter() {
            this.fetchVideos();
        },
    },

    methods: {
        viewVideo(i) {
            if (typeof this.videos[i] === 'undefined') {
                this.activeVideoIndex = null;
                return;
            }
            this.activeVideoIndex = i;
            axios.post('/api/videos/' + this.videos[i].uuid + '/views');
        },

        fetchVideos() {
            axios.get(this.fetchUrl).then(response => {
                this.videos = response.data.data;
                this.ready = true;
            });
        },
    },
};
</script>

<style>
.flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.flex-item-video {
    margin: 5px;
    width: 130px;
    height: 97.5px;
    cursor: pointer;
}
</style>
