<template>
    <div>
        <!-- input for use in non ajax html form -->
        <input type="hidden" name="venue_uuid" v-model="selectedVenue.uuid" />

        <!-- .is-error.el-form-item adds a red border -->
        <div class="form-group" :class="{ 'is-error': error }">
            <v-select
                :filterable="false"
                label="title"
                class="venue-select"
                v-model="selectedVenue"
                @input="handleChange"
                placeholder="Type to search venues.."
                @search="onSearch"
                :options="venues"
            >
                <template slot="option" slot-scope="option">
                    <span>
                        {{ option.title }}
                        <span class="d-block">
                            {{ option | cityState }}
                        </span>
                    </span>
                    <span :class="option.icon">
                        <img class="img-thumbnail" :src="option.img_url + '?w=60&h=60&fit=crop'" />
                    </span>
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        vSelect,
    },
    props: {
        size: {
            type: String,
            default: '',
        },

        error: {
            type: String,
        },

        // venue.uuid
        value: {
            type: Object,
            required: false,
        },
    },

    computed: {
        venue_uuid() {
            if (this.value) {
                return this.value.data.uuid;
            }
        },
    },

    data() {
        return {
            query: '',
            selectedVenue: '',
            venues: [],
            cancelToken: axios.CancelToken,
            currentRequest: null,
        };
    },

    created() {
        if (this.venue_uuid) {
            this.loadVenue(this.venue_uuid);
        } else {
            this.fetchVenues();
        }
    },

    methods: {
        handleChange(e) {
            this.$emit('change', e);
        },

        onSearch(search, loading) {
            loading(true);
            this.fetchVenues(loading, search);
        },

        loadVenue(uuid) {
            this.selectedVenue = uuid;
            axios.get('/api/venues/' + uuid).then(res => {
                this.selectedVenue = res.data.data;
            });
        },

        fetchVenues(loading, search = '') {
            if (this.currentRequest) {
                this.currentRequest.cancel();
            }

            this.loading = true;
            this.currentRequest = this.cancelToken.source();
            let url = `/api/venues?sort_by=relevance&limit=${Config.multiselect_search_result_count}&q=${search}`;
            return axios
                .get(url, {
                    cancelToken: this.currentRequest.token,
                })
                .then(response => {
                    this.venues = response.data.data;
                    loading(false);
                    this.currentRequest = null;
                })
                .catch(() => {
                    // don't err after cancel
                });
        },
    },
};
</script>
<style>
.dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.dropdown li:last-child {
    border-bottom: none;
}
.dropdown li a {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.dropdown li a .fa {
    padding-right: 0.5em;
}
</style>
