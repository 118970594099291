<script>
export default {
    data() {
        return {
            q: '',
        };
    },

    methods: {
        search() {
            window.location.href = '/search?q=' + encodeURIComponent(this.q);
        },
    },
};
</script>
