<template>
    <div>
        <table v-if="!loading" class="mb-0 table table-hover bordered">
            <thead class="bg-primary">
                <tr class="small text-white">
                    <th v-if="quickpitch" class="pr-0 text-right">
                        <input :checked="allChecked" @click="handleCheck($event, venues)" type="checkbox" />
                    </th>
                    <th class="cursor-pointer" @click="$emit('sortBy', 'title')">
                        <i :class="sortClass('title')"></i>
                        Venue
                    </th>
                    <th class="cursor-pointer d-none d-md-table-cell" @click="$emit('sortBy', 'capacity')">
                        <i :class="sortClass('capacity')"></i>
                        Capacity
                    </th>
                    <th class="cursor-pointer d-none d-md-table-cell" @click="$emit('sortBy', 'age')">
                        <i :class="sortClass('age')"></i>
                        Age
                    </th>
                    <th v-show="isSortedBy('distance')" class="cursor-pointer" @click="$emit('sortBy', 'distance')">
                        <i :class="sortClass('distance')"></i>
                        Distance
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="venue in venues">
                    <td v-if="quickpitch" class="pr-0 text-right">
                        <input @click="handleCheck($event, [venue])" type="checkbox" :checked="inQuickpitch(venue)" />
                    </td>
                    <td class="row">
                        <div class="col-auto">
                            <img v-if="venue.img_url" class="img-fluid" :src="venue.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="music-default-sm img-fluid" src="../../../../public/img/local_music.jpg" />
                        </div>
                        <div class="col d-md-table-cell">
                            <h6 class="mb-0">
                                <a :href="'/venues/' + venue.slug">{{ venue.title }}</a>
                            </h6>
                            <span v-if="venue.categories.data.length" class="d-flex flex-row flex-wrap pb-1">
                                <span class="badge badge-secondary venueCategoryBadge"
                                    v-for="category in venue.categories.data">
                                    {{ category.name }}
                                </span>
                            </span>
                            <span class="d-flex">
                                <star-rating :readOnly="true" :uuid="venue.uuid" :rating="venue.rating"></star-rating>
                                <span class="pl-1"> ({{ venue.vote_count }}) </span>
                            </span>
                            <p class="mb-0">
                                {{ venue | cityState }}
                            </p>
                            <p class="mb-0">
                                {{ venue.genres.data | genreString }}
                            </p>
                            <p v-if="venue.blacklist_genres.data.length">
                                <b>No:</b> {{ venue.blacklist_genres.data | genreString }}
                            </p>
                        </div>
                    </td>
                    <td class="d-none d-md-table-cell">{{ venue.capacity || '' }} {{ venue.capacity_extra || '' }}</td>
                    <td class="d-none d-md-table-cell">{{ venue.age ? venue.age + '+' : 'All' }}</td>
                    <td v-show="isSortedBy('distance')">{{ venue.distance.toFixed(1) }} miles</td>
                </tr>
            </tbody>
        </table>
        <div v-if="venuesEmpty" class="card card-body text-center">
            <p class="m-3">No venues found.</p>
        </div>
        <loading style="height: 10vh" v-show="loading"></loading>

        <deluxe-modal v-model="showDeluxeModal"></deluxe-modal>
    </div>
</template>

<script>
import loading from '../loading.vue';
import deluxeModal from '../deluxeModal.vue';
import starRating from '../rating/rating.vue';

export default {
    props: ['quickpitch', 'isDeluxe', 'loading', 'venues', 'query', 'showDistance'],

    name: 'venues-results',

    components: { deluxeModal, starRating, loading },

    data() {
        return {
            showDeluxeModal: false,
        };
    },

    computed: {
        venuesEmpty() {
            return !this.loading && !this.venues[0];
        },

        allChecked() {
            if (!this.quickpitch) {
                return;
            }

            const v = this.venues.map(i => i.uuid);
            const q = this.quickpitch.venues.data.map(i => i.uuid);

            const unchecked = v.filter(i => {
                return q.indexOf(i) < 0;
            });

            return !unchecked.length;
        },
    },

    methods: {
        handleCheck($event, venues) {
            if (!this.quickpitch || !window.User.is_deluxe) {
                this.showDeluxeModal = true;
                return;
            }

            var oldVenues = this.quickpitch.venues.data.map(i => i.uuid);
            var changedVenues = venues.map(i => i.uuid);

            var newVenues;
            if ($event.target.checked) {
                newVenues = oldVenues.concat(changedVenues);
            } else {
                newVenues = oldVenues.filter(v => {
                    return changedVenues.indexOf(v) < 0;
                });
            }

            this.$emit('quickpitchUpdate', newVenues);
        },

        inQuickpitch(venue) {
            if (!this.quickpitch) {
                return;
            }

            return this.quickpitch.venues.data.map(i => i.uuid).indexOf(venue.uuid) > -1;
        },

        sortClass(field) {
            return {
                'd-none': !(this.query.sort_by === field),
                fa: true,
                'fa-sort-amount-asc': this.query.sort_order === 'ascending',
                'fa-sort-amount-desc': this.query.sort_order === 'descending',
            };
        },

        isSortedBy(field) {
            return this.query.sort_by === field;
        },
    },
};
</script>
