<template>
    <div v-show="visible">
        <div class="card">
            <div class="card-body">
                <div id="map"></div>
            </div>
        </div>
    </div>
</template>

<script>
/* global google */
import $script from 'scriptjs';

export default {
    props: {
        visible: {
            default: false,
            type: Boolean,
        },
        items: {
            required: true,
            type: Array,
        },
    },

    name: 'item-map',
    data() {
        return {};
    },

    created() {
        const gmaps = 'https://maps.googleapis.com/maps/api/js?key=' + window.google_maps_js_key;

        $script(gmaps, this.drawMap);
    },

    watch: {
        items() {
            this.drawMap();
        },
        visible() {
            this.$nextTick(this.drawMap);
        },
    },

    computed: {
        hasCoordinates() {
            return (
                this.items &&
                this.items.find(i => {
                    return i.latitude && i.longitude;
                })
            );
        },
    },

    methods: {
        drawMap() {
            // dont draw map unless it is open
            if (!this.visible) {
                return;
            }

            // Create a map object and specify the DOM element for display.
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 39.8282, lng: -98.5795 },
                scrollwheel: false,
                zoom: 8,
            });

            if (this.hasCoordinates) {
                const bounds = new google.maps.LatLngBounds();
                const mappable = this.items.filter(i => {
                    return i.latitude && i.longitude;
                });

                // Create a marker and set its position.
                mappable.forEach(i => {
                    const marker = new google.maps.Marker({
                        map: map,
                        position: { lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) },
                        title: i.title || i.name,
                    });

                    const infowindow = new google.maps.InfoWindow({
                        content: this.infoWindow(i),
                    });

                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });

                    // auto center and zoom with bounds
                    const loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                    bounds.extend(loc);
                });

                // Expand bounds if it's too small
                if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                    var extendPoint = new google.maps.LatLng(
                        bounds.getNorthEast().lat() + 0.01,
                        bounds.getNorthEast().lng() + 0.01
                    );
                    bounds.extend(extendPoint);
                }
                map.fitBounds(bounds);
                map.panToBounds(bounds); // auto-center
            }
        },

        infoWindow(item) {
            return (
                '<h3>' +
                (item.title || item.name || item.display_name) +
                '</h3>' +
                "<a href='" +
                window.location.pathname +
                '/' +
                (item.slug || item.username || item.uuid) +
                "'>View</a>"
            );
        },

        closeMap() {
            this.$emit('closeMap');
        },
    },
};
</script>

<style>
#map {
    height: 30vh;
}
</style>
