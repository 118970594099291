<template>
    <div>
        <h1 class="h4">Notifications</h1>

        <div class="d-flex align-items-center justify-content-between">
            <p>You have {{ unreadCount }} unread notification{{ unreadCount !== 1 ? 's' : '' }}.</p>
            <div class="ml-auto mr-2 d-flex justify-content-end align-items-center">
                <p class="mr-1" v-if="!selectedAll">
                    Selected <b>{{ selected.length }}</b
                    >/{{ pagination.total }}
                </p>
                <p v-else class="mr-1">
                    Selected <b>{{ pagination.total }}</b
                    >/{{ pagination.total }}
                </p>
                <p>
                    <button class="btn btn-link" @click="selectAll" v-if="selectedAll">None</button>
                    <button class="btn btn-link" :disabled="notifications.length === 0" @click="selectAll" v-else>
                        All
                    </button>
                </p>
                <button
                    class="btn btn-danger"
                    :disabled="selected.length === 0 && selectedAll === false"
                    @click="deleteSelected"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">Mark</button>
                <div class="dropdown-menu dropdown-menu-right">
                    <button
                        :disabled="selected.length === 0 && selectedAll === false"
                        class="dropdown-item btn btn-link"
                        @click="markAllRead"
                    >
                        Read
                    </button>
                    <button
                        :disabled="selected.length === 0 && selectedAll === false"
                        class="dropdown-item btn btn-link"
                        @click="markUnread"
                    >
                        Unread
                    </button>
                </div>
            </div>
        </div>

        <div v-if="notifications.length === 0 && !loading" class="text-center mt-2 mb-0 alert alert-info">
            No notifications found
        </div>

        <div v-else class="mt-2">
            <table class="table table-nolines table-hover">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                :checked="toggled || selectedAll"
                                class="input-group-check"
                                @click="toggleAll"
                            />
                        </th>

                        <th>Notification</th>
                        <th class="text-right">Created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(n, key) in notifications">
                        <td>
                            <input
                                @click.stop="toggleItem(n)"
                                type="checkbox"
                                class="input-group-check"
                                :checked="selected.includes(n) || selectedAll"
                            />
                        </td>
                        <td>
                            <a
                                class="cursor-pointer"
                                :class="{ 'text-muted': n.read_at, 'text-primary': !n.read_at }"
                                @click="viewNotification(n)"
                            >
                                <i v-if="!n.read_at" class="pr-2 fa fa-info"></i>
                                {{ n.data.subject | truncate(70, '...') }}
                            </a>
                        </td>
                        <td class="text-right">
                            {{ n.created_at | fromNow }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination
            v-if="notifications.length"
            :pagination="pagination"
            :loading="loading"
            @pageChange="updatePage"
        ></pagination>
    </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import Query from '../../query/query.js';

export default {
    components: { pagination },

    props: {
        uuid: {
            required: true,
            type: String,
        },
    },

    name: 'notifications-index',

    data() {
        return {
            query: new Query({
                sort_by: 'status',
                sort_order: 'descending',
                page: '',
            }),
            pagination: {
                current_page: 1,
            },
            notifications: [],
            toggled: false,
            selectedAll: false,
            selected: [],
            notificationFetchError: false,
            loading: true,
        };
    },
    created() {
        this.query.parse(location.search);
        this.fetchNotifications();
    },

    computed: {
        unreadCount() {
            return window.User.unread_notification_count;
        },
    },

    methods: {
        selectAll() {
            if (!this.selectedAll) {
                this.selected = [];
                this.selectedAll = true;
                this.toggled = true;
            } else {
                this.selectedAll = false;
                this.toggled = false;
            }
        },

        toggleItem(val) {
            if (this.selectedAll === true) {
                this.selectedAll = false;
                this.toggled = false;
                var index = this.notifications.indexOf(val);
                this.notifications.forEach(function (n) {
                    this.selected.push(n);
                }, this);
                this.selected.splice(index, 1);
            } else if (this.selected.includes(val)) {
                var index = this.selected.indexOf(val);
                this.selected.splice(index, 1);
            } else {
                this.selected.push(val);
            }
        },
        toggleAll() {
            this.selectedAll = false;
            if (this.toggled) {
                this.selected = [];
                this.toggled = false;
            } else {
                this.selected = [];
                this.notifications.forEach(function (n) {
                    this.selected.push(n);
                }, this);
                this.toggled = true;
            }
        },
        viewNotification(n) {
            this.markRead(n);
        },

        fetchNotifications() {
            this.loading = true;
            axios
                .get('/api/notifications?' + this.query.toString())
                .then(response => {
                    this.loading = false;
                    this.pagination = response.data.meta.pagination;
                    this.notifications = response.data.data;
                })
                .catch(error => {
                    this.loading = false;
                    this.notificationFetchError = error.data;
                });
        },

        updatePage(number) {
            this.query.page = number;
            this.pagination.current_page = number;
            this.query.push();
            this.fetchNotifications();
            this.selected = [];
            this.toggled = false;
            this.selectedAll = false;
        },
        deleteSelected() {
            var ids = this.selected.map(x => x.id);
            return new Promise((resolve, reject) => {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to undo this!',
                    icon: 'warning',
                    buttons: [true, 'Yes, delete it!'],
                    dangerMode: true,
                }).then(willDelete => {
                    if (willDelete) {
                        resolve(
                            axios
                                .put('/api/notifications/delete', {
                                    ids: ids,
                                })
                                .then(response => {
                                    window.location.href = '/notifications';
                                })
                        );
                    }
                });
            });
        },
        markUnread() {
            var ids = this.selected.map(x => x.id);
            axios
                .put('/api/notifications/mark-unread', {
                    ids: ids,
                })
                .then(response => {
                    window.location.reload();
                });
        },
        markRead(n) {
            var ids = n.id;
            axios
                .put('/api/notifications/mark-read', {
                    ids: [ids],
                })
                .then(response => {
                    window.location = '/notifications/' + n.id;
                });
        },
        markAllRead() {
            var ids = this.selected.map(x => x.id);
            axios
                .put('/api/notifications/mark-read', {
                    ids: ids,
                })
                .then(response => {
                    window.location.reload();
                });
        },
    },
};
</script>

<style>
.table-nolines th {
    border-top: none;
}
.table-nolines td {
    border-top: none;
}
</style>
