<template>
    <div>
        <table v-if="!loading" class="table table-hover bordered">
            <thead class="bg-primary">
                <tr class="small text-white">
                    <th>Availability</th>
                    <th class="cursor-pointer" @click="$emit('sortBy', 'date')">
                        <i :class="sortClass('date')"></i>
                        Date
                    </th>
                    <th class="d-none d-md-table-cell cursor-pointer" @click="$emit('sortBy', 'created_at')">
                        <i :class="sortClass('created_at')"></i>
                        Created
                    </th>
                    <th v-show="showDistance" class="cursor-pointer" @click="$emit('sortBy', 'distance')">
                        <i :class="sortClass('distance')"></i>
                        Distance
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="availability in availabilities">
                    <td class="row">
                        <div class="col-auto">
                            <img v-if="availability.img_url" class="img-fluid"
                                :src="availability.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word"
                                    :href="'/band-availabilities/' + availability.slug + '/' + availability.uuid">
                                    {{ availability.title | truncate(100, '...') }}
                                </a>
                            </h6>
                            <p class="mb-0 break-word">
                                {{ availability.band_name }}
                            </p>
                            <p class="mb-0">
                                {{ availability | cityState }}
                            </p>
                            <p v-if="availability.genres" class="mb-0">
                                {{ availability.genres.data | genreString }}
                            </p>
                        </div>
                    </td>
                    <td class="date-cell">{{ availability.date | dateFormat('MM-DD-YY') }}</td>
                    <td class="d-none d-md-table-cell date-cell">
                        {{ availability.created_at | fromUTC('MM-DD-YY') }}
                    </td>

                    <td v-if="showDistance">{{ availability.distance.toFixed(1) }} miles</td>
                </tr>
            </tbody>
        </table>
        <div v-if="availabilitiesEmpty" class="card card-body text-center">
            <p class="m-3">No availabilities found.</p>
        </div>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';

export default {
    props: ['loading', 'availabilities', 'query', 'showDistance'],

    name: 'availability-results',

    components: { loading },

    computed: {
        availabilitiesEmpty() {
            return !this.loading && !this.availabilities.length;
        },
    },

    methods: {
        sortClass(field) {
            return {
                'd-none': !(this.query.sort_by === field),
                fa: true,
                'fa-sort-amount-asc': this.query.sort_order === 'ascending',
                'fa-sort-amount-desc': this.query.sort_order === 'descending',
            };
        },
    },
};
</script>
