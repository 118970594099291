<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="container">
                    <h1 class="h4 row justify-content-between">IOTM Subscriptions</h1>
                </div>
            </div>
            <div class="card-body">
                <div v-if="subscriptions.length">
                    <ul class="list-group mb-2">
                        <li class="list-group-item bg-primary">
                            <span class="text-white">Subscriptions</span>
                        </li>
                        <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                            v-for="s in subscriptions"
                            :key="s.id"
                        >
                            <span>
                                {{ s.name }}
                            </span>
                            <a class="btn btn-sm btn-link mr-auto" :href="planDetails(s)"> Plan Details </a>
                            <span class="d-flex justify-content-end">
                                <span v-if="s.ends_at">expires {{ s.ends_at }}</span>

                                <div v-else-if="s.stripe_price">
                                    <span class="mx-auto" v-if="s.period_end">renews {{ s.period_end }}</span>

                                    <a href="/billing" class="btn btn-sm btn-secondary">
                                        Cancel
                                    </a>
                                </div>
                                <span v-else-if="s.name.includes('Upgrade')">
                                    You have some {{ s.type }} features, subscribe to a plan to gain access to the rest
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>

                <div v-if="plans.length">
                    <ul class="list-group">
                        <li class="list-group-item bg-primary">
                            <span class="text-white">Available Plans</span>
                        </li>
                        <div class="bg-white">
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                                v-for="p in plans"
                                :key="p.uuid"
                            >
                                {{ p.name }}
                                <a class="btn btn-sm btn-link" :href="planDetails(p)"> Plan Details </a>
                                <span class="mx-auto"
                                    >${{ p.price.toFixed(2) }} / {{ p.interval | intervalFilter }}</span
                                >
                                <span class="ml-auto">
                                    <a href="/billing" class="btn btn-sm btn-primary">
                                        Subscribe
                                    </a>
                                </span>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import modal from '../../components/modal.vue';

export default {
    components: { modal },
    name: 'edit-subscriptions',

    data() {
        return {
            credits: null,
            billingModal: false,
            coupon: null,
            plans: [],
            subscriptions: [],
            existingCustomer: false,
            subscribing: '',
            deleting: '',
            state: 'none',
            error: null,
            termsCheck: false,
            termsError: false,
        };
    },

    created() {
        this.fetchSubscriptions();
    },

    methods: {
        planDetails(plan) {
            return `/subscriptions/${plan.type}`;
        },

        fetchSubscriptions() {
            this.deleting = '';
            this.subscribing = '';
            this.state = 'none';
            new Promise(resolve => {
                axios
                    .all([this.fetchUserSubscriptions(), this.fetchPlans()])
                    .then(
                        axios.spread((subscriptions, plans) => {
                            this.subscriptions = subscriptions.data.data;
                            this.plans = plans.data.data.filter(this.visiblePlan);
                            resolve();
                        })
                    )
                    .then(() => {
                        this.filterCredits();
                    });
            });
        },

        filterCredits() {
            if (this.subscriptions.length) {
                const credits = this.subscriptions[0].remainder;
                this.credits = credits;
            }
        },

        visiblePlan(plan) {
            const sub = this.subscriptions.find(s => {
                return s.type === plan.type;
            });

            if (sub && sub.name.includes('Upgrade')) {
                return true;
            }

            return !sub || (plan.price > sub.price && sub.price) || sub.ends_at;
        },

        fetchUserSubscriptions() {
            return axios.get('/api/subscriptions');
        },

        fetchPlans() {
            return axios.get('/api/plans');
        },
    },
};
</script>
