<template>
    <div>
        <button @click="handleClick" class="btn btn-sm btn-secondary m-1">
            Contact<span class="d-none d-md-inline"> Booker</span>
        </button>

        <premium-modal v-model="showModal"></premium-modal>

        <modal @click.stop :width="600" v-show="showForm" @close="handleClose">
            <form
                @input="form.clearErrors()"
                @click.stop
                @submit.prevent
                slot="body"
            >
                <div class="form-group">
                    <label>To: {{ to }}</label>
                </div>

                <div class="form-group">
                    <label>From: {{ from }}</label>
                </div>

                <div v-if="isDeluxe" class="form-group">
                    <label for="pitch">Load Pitch:</label>
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <select
                                name="pitch"
                                class="form-control"
                                @input="handlePitchSelect"
                                :value="form.pitch"
                            >
                                <option :value="null" key="" disabled>
                                    None
                                </option>
                                <option
                                    v-for="pitch in pitches"
                                    :value="pitch.uuid"
                                    :key="pitch.uuid"
                                    :selected="pitch.uuid === form.pitch"
                                >
                                    {{ pitch.pitch_subject }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <a href="/pitches" target="_blank"
                                >Manage Saved Pitches</a
                            >
                        </div>
                    </div>
                </div>

                <dynamic-subject-field
                    ref="subjectField"
                    :errors="form.errors"
                    :subject="form.subject"
                    :dynamic="false"
                    v-model="form.subject"
                    @input="handleSubjectChange"
                ></dynamic-subject-field>

                <div
                    class="form-group"
                    :class="{ 'has-danger': form.errors.get('message') }"
                >
                    <label>Message *</label>
                    <trix
                        ref="vuetrix"
                        inputId="bodyTrix"
                        v-model="form.message"
                        placeholder=""
                        @input="handleBodyChange"
                        :class="{
                            'form-control-danger': form.errors.get('message'),
                        }"
                    />
                    <span
                        class="form-control-feedback"
                        v-show="form.errors.has('message')"
                        v-text="form.errors.get('message')"
                    ></span>
                </div>

                <div class="d-flex">
                    <button
                        @click.prevent="handleClose"
                        class="btn btn-secondary"
                    >
                        Cancel
                    </button>
                    <btn-state
                        id="submit-button"
                        @click="submit"
                        :state="form.state"
                        btnClass="ml-2 btn btn-primary"
                    >
                        Send
                    </btn-state>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
/* global $ */

import BtnState from "../btnState.vue";
import dynamicSubjectField from "../../components/dynamicSubjectField.vue";
import premiumModal from "../premiumModal.vue";
import Form from "../../form/form.js";
import modal from "../modal.vue";
import trix from "../trix-wysiwyg.vue";

export default {
    name: "contact-booker",

    components: { modal, premiumModal, BtnState, trix, dynamicSubjectField },

    props: {
        link: {
            type: String,
        },
        to: {
            type: String,
        },
        from: {
            type: String,
            required: true,
        },
        venue: {
            type: String,
            required: true,
        },
        pitchLimit: {
            default: 5,
        },
    },

    data() {
        return {
            savePitchState: "none",
            pitch: {},
            pitches: [],
            showForm: false,
            showModal: false,
            user: false,
            form: new Form({
                venue: this.venue,
                subject: "",
                message: "",
                pitch: "",
                default: null,
            }),
        };
    },

    created() {
        this.user = window.User;
        if (this.user.is_deluxe) {
            this.fetchSavedPitches().then(() => {
                const defaultPitch = this.pitches.find(
                    (pitch) => pitch.default
                );
                if (defaultPitch) {
                    this.loadPitch(defaultPitch);
                    this.form.default = true;
                }
            });
        }
    },

    computed: {
        savePitchDisabled() {
            // reached limit || existing pitch || subject and message not filled
            return !!(
                this.pitches.length >= this.pitchLimit ||
                this.form.pitch ||
                !(this.form.subject && this.form.message)
            );
        },
        isDeluxe() {
            return window.User.is_deluxe;
        },
    },

    methods: {
        handleBodyChange(event) {
            if (this.form.pitch && event && event.length) {
                let newBody = $(event).html(event).text().length;
                let pitchBody = $(this.pitch.body)
                    .html(this.pitch.body)
                    .text().length;
                let bodyHasChanges = newBody !== pitchBody;
                this.form.pitch = bodyHasChanges ? null : this.pitch.uuid;
                this.form.default = bodyHasChanges ? false : true;
            }
        },
        handleSubjectChange(event) {
            if (this.form.pitch && event && event.length) {
                let newSubject = $(event).html(event).text().length;
                let pitchSubject = $(this.pitch.subject)
                    .html(this.pitch.subject)
                    .text().length;
                let subjectHasChanges = newSubject !== pitchSubject;
                this.form.pitch = subjectHasChanges ? null : this.pitch.uuid;
                this.form.default = subjectHasChanges ? false : true;
            }
        },
        fetchSavedPitches() {
            return axios.get("/api/pitches").then((res) => {
                this.pitches = res.data.data;
            });
        },

        handleClick() {
            if (this.to && window.User.is_premium) {
                this.showForm = true;
            } else if (this.link && window.User.is_premium) {
                // equivalent of noopener
                const newWindow = window.open();
                newWindow.opener = null;
                newWindow.location = this.link;
            } else {
                this.showModal = true;
            }
        },

        handleClose() {
            this.showForm = false;
            if (this.form.state === "success") {
                this.form = new Form({
                    venue: this.venue,
                    subject: "",
                    message: "",
                });
            }
        },

        submit() {
            this.form
                .post("/api/inquiry-venues")
                .then(() => {
                    setTimeout(() => {
                        this.handleClose();
                    }, 1000);
                })
                .catch((e) => {});
        },

        savePitch() {
            this.savePitchState = "processing";
            return axios
                .post("/api/pitches", {
                    subject: this.form.subject,
                    message: this.form.message,
                })
                .then((res) => {
                    this.form.pitch = res.data.data.uuid;
                    this.fetchSavedPitches();
                    this.savePitchState = "success";
                    window.setTimeout(() => {
                        this.savePitchState = "none";
                    }, 2000);
                })
                .catch((err) => {
                    this.savePitchState = "error";
                    window.setTimeout(() => {
                        this.savePitchState = "none";
                    }, 2000);
                });
        },

        handleSubjectBodyChange() {
            this.form.default = false;
            this.form.pitch = null;
        },

        setDefaultPitch() {
            axios
                .put("/api/pitches/" + this.form.pitch, {
                    body: this.form.message,
                    subject: this.form.subject,
                    default: true,
                })
                .then(() => {
                    this.fetchSavedPitches();
                });
        },

        handlePitchSelect(event) {
            const pitch = this.pitches.find(
                (pitch) => pitch.uuid === event.target.value
            );
            this.form.default = pitch.default;
            this.loadPitch(pitch);
        },

        loadPitch(pitch) {
            this.pitch = pitch;
            this.form.subject = pitch.subject;
            this.form.message = pitch.body;
            this.$refs.vuetrix.$refs.trixPreview.$refs.trix.value = pitch.body;
            this.$refs.subjectField.$refs.trixSubjectField.$refs.trix.value =
                pitch.subject;
            this.form.pitch = pitch.uuid;
        },
    },
};
</script>
