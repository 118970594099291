<template>
    <div>
        <div class="d-none d-lg-flex card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="mb-0">IOTM News</h4>
                <a href="/newsletter/subscribe" class="btn btn-sm btn-primary"> Join Our Newsletter </a>
            </div>

            <div class="card-body twitter-link-container">
                <a 
                    style="font-size: 1.5rem; color: black; font-weight: 600" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    href="https://twitter.com/Indieonthemove">
                    Tweets From @Indieonthemove
                </a>
            </div>
        </div>

        <div class="d-flex justify-content-center" v-if="!hideAds">
            <div id="ads-medium-rectangle" class="d-none d-lg-flex mt-2">
                <script type="application/javascript" async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                <ins class="adsbygoogle" 
                    style="height:250px; width:300px; display:block;"
                    data-ad-client="ca-pub-7294664740924242" 
                    data-ad-slot="6104773750"
                    data-ad-format="medium-rectangle"
                ></ins>
                <script type="application/javascript">
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
            </div>
        </div>

        <div class="d-none d-lg-flex card mt-2">
            <div class="card-header d-flex justify-content-between">
                <h4 class="mb-0">Venue Reviews</h4>
                <a href="/venue-commentsreviews" class="btn btn-sm btn-primary"> More Reviews </a>
            </div>
            <div class="card-body">
                <reviews limit="5"></reviews>
            </div>
        </div>

        <div class="d-flex justify-content-center" v-if="!hideAds">
            <div id="ads-skyscraper" class="d-none d-lg-flex mt-2">
                <script type="application/javascript" async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                <ins class="adsbygoogle" 
                    style="height:600px; width:300px; display:block;"
                    data-ad-client="ca-pub-7294664740924242" 
                    data-ad-slot="6104773750" 
                    data-ad-format="skyscraper"
                ></ins>
                <script type="application/javascript">
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
            </div>
        </div>
    </div>
</template>

<script>
import reviews from '../components/home/reviews.vue';
// import $script from 'scriptjs';

export default {
    components: { reviews },

    props: {
        statusCode: {
            default: 200,
        },
    },

    mounted() {
        // this.$nextTick(() => {
        //     const twt = '//platform.twitter.com/widgets.js';

        //     $script(twt, () => {
        //         const scroll = document.querySelector('.twitter-scroll-container');
        //         let waiting = 0;
        //         const hideTweetsBy = window.setInterval(() => {
        //             const inner = scroll.firstChild;
        //             // dont wait longer than 10 seconds
        //             if (waiting >= 100 || (inner.clientHeight && inner.nodeName === 'IFRAME')) {
        //                 scroll.scrollTop += 50;
        //                 clearInterval(hideTweetsBy);
        //             } else {
        //                 waiting++;
        //             }
        //         }, 100);
        //     });
        // });

        this.adsenseMediumRectangle = document.getElementById('ads-medium-rectangle').innerHTML;
        this.adsenseSkyScraper = document.getElementById('ads-skyscraper').innerHTML;
    },
    computed: {
        hideAds() {
            return (window.User && window.User['old_browser']) || this.statusCode != 200;
        },
    },
};
</script>

<style>
.twitter-scroll-container {
    max-height: 40vh;
    overflow: scroll;
}

.twitter-link-container:hover {
    background: rgba(29, 155, 240, .1);
    text-decoration: underline;
}
</style>
