<template>
    <div class="categorySelect">
        <input type="hidden" :name="name" v-model="categoriesString" />

        <div class="el-form-item mb-0" :class="{ 'is-error': error }">
            <el-select
                multiple
                :value="value"
                :placeholder="placeholder"
                :size="size"
                clearable
                filterable
                no-match-text="No results"
                ref="input"
                @change="handleChange"
            >
                <el-option v-for="category in options" :key="category.id" :label="category.name" :value="category.id">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isFilter: {
            default: false,
        },

        size: {
            type: String,
            default: '',
        },

        error: {
            type: String,
        },

        name: {
            type: String,
            default: 'categories',
        },

        value: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            options: [],
        };
    },

    created() {
        this.fetchCategories();
    },

    computed: {
        placeholder() {
            return this.isFilter ? 'All Categories' : 'Select Categories';
        },

        categoriesString() {
            return this.value.reduce((acc, g) => {
                return !acc ? g.toString() : acc + ',' + g;
            }, '');
        },
    },

    methods: {
        handleChange(val) {
            this.$emit('category-input', val);
        },

        fetchCategories() {
            return axios.get('/api/venue-categories').then(response => {
                this.options = response.data.data;
            });
        },
    },
};
</script>
