<template>
    <div class="daySelect">
        <el-select
            :size="size"
            clearable
            multiple
            filterable
            @change="updateValue"
            :value="value"
            placeholder="Select Music Days/Nights"
        >
            <el-option v-for="day in days" :label="day" :key="day" :value="day"> </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            default: '',
        },

        value: {
            required: false,
            type: Array,
        },
    },

    data() {
        return {
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        };
    },

    methods: {
        updateValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
