<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9 mb-2">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Profile Information</h4>
                        <a class="btn btn-sm btn-outline-primary" href="/media-photos"> My Media </a>
                    </div>
                    <div v-if="form">
                        <div class="card-body px-4" @keydown="form.clearErrors($event.target.name)">
                            <div class="form-group row mt-2" :class="{ 'has-danger': form.errors.get('username') }">
                                <div class="col-lg-6">
                                    <label for="username"><small class="text-center">Username *</small></label>
                                    <input
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('username') }"
                                        v-model="form.username"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('username')"
                                        v-text="form.errors.get('username')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row mt-2" :class="{ 'has-danger': form.errors.get('display_name') }">
                                <div class="col-lg-6">
                                    <label for="display_name"
                                        ><small class="text-center">Band/Company/Act/Venue Name</small></label
                                    >
                                    <input
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('display_name') }"
                                        v-model="form.display_name"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('display_name')"
                                        v-text="form.errors.get('display_name')"
                                    ></span>
                                </div>
                            </div>

                            <!--
              <div class="form-group row mt-2" :class="{'has-danger' : form.errors.get('slug')}">
                <div class="col-lg-6">
                  <label for="slug"><small class="text-center">Profile Url *</small></label>
                  <input type="text" name="name" class="form-control"
                  :class="{'form-control-danger' : form.errors.get('slug')}" v-model="form.slug">
                  <span class="form-control-feedback" v-show="form.errors.has('slug')" v-text="form.errors.get('slug')"></span>
                </div>
              </div>
              -->

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('email') }">
                                <div class="col-lg-6">
                                    <label for="email"><small>Email *</small></label>
                                    <input
                                        type="text"
                                        name="email"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('email') }"
                                        v-model="form.email"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('email')"
                                        v-text="form.errors.get('email')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('first_name') }">
                                <div class="col-lg-6">
                                    <label for="first_name"><small>First Name *</small></label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('first_name') }"
                                        v-model="form.first_name"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('first_name')"
                                        v-text="form.errors.get('first_name')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('last_name') }">
                                <div class="col-lg-6">
                                    <label for="last_name"><small>Last Name *</small></label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('last_name') }"
                                        v-model="form.last_name"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('last_name')"
                                        v-text="form.errors.get('last_name')"
                                    ></span>
                                </div>
                            </div>

                            <!-- Genres -->

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('genres') }">
                                <label for="genres"><small>Genres *</small></label>
                                <genre-select
                                    size="mini"
                                    @input="form.clearErrors()"
                                    name="genres"
                                    :error="form.errors.get('genres')"
                                    v-model="form.genres"
                                ></genre-select>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('genres')"
                                    v-text="form.errors.get('genres')"
                                >
                                </span>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('type') }">
                                <div class="col-lg-4">
                                    <label for="type"><small>Profile Type *</small></label>
                                    <select
                                        name="type"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('type') }"
                                        v-model="form.type"
                                    >
                                        <option value="agent">Agent</option>
                                        <option value="band">Band/Artist</option>
                                        <option value="label">Label</option>
                                        <option value="manager">Manager</option>
                                        <option value="promoter">Promoter</option>
                                        <option value="venue">Venue</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('type')"
                                        v-text="form.errors.get('type')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('bio') }">
                                <div class="col-lg-10">
                                    <label for="bio"><small>About</small></label>
                                    <textarea
                                        type="text"
                                        name="bio"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('bio') }"
                                        v-model="form.bio"
                                    ></textarea>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('bio')"
                                        v-text="form.errors.get('bio')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('localclubs') }">
                                <div class="col-lg-10">
                                    <label for="localclubs"><small>Local Clubs</small></label>
                                    <textarea
                                        type="text"
                                        name="localclubs"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('localclubs') }"
                                        v-model="form.localclubs"
                                    ></textarea>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('localclubs')"
                                        v-text="form.errors.get('localclubs')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('website') }">
                                <div class="col-lg-6">
                                    <label for="website"><small>Website</small></label>
                                    <input
                                        type="text"
                                        name="website"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('website') }"
                                        v-model="form.website"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('website')"
                                        v-text="form.errors.get('website')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('soundcloud') }">
                                <div class="col-lg-6 col-12">
                                    <label for="soundcloud"><small>Soundcloud Username or Url</small></label>
                                    <input
                                        type="text"
                                        name="soundcloud"
                                        class="form-control"
                                        placeholder="zelazowa or https://soundcloud.com/zelazowa"
                                        :class="{ 'form-control-danger': form.errors.get('soundcloud') }"
                                        v-model="form.soundcloud"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('soundcloud')"
                                        v-text="form.errors.get('soundcloud')"
                                    >
                                    </span>

                                    <label>
                                        <small class="font-weight-bold">Displays latest tracks on your profile</small>
                                        <p class="small mb-0">
                                            Don't have a SoundCloud account? Create one
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.soundcloud.com"
                                            >
                                                HERE.
                                            </a>
                                        </p>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('bandsintown_id') }">
                                <div class="col-lg-6">
                                    <label for="bandsintown_id"><small>Bands In Town ID or Url</small></label>
                                    <input
                                        type="text"
                                        name="bandsintown_id"
                                        class="form-control"
                                        placeholder="3226 or https://www.bandsintown.com/a/3226"
                                        :class="{ 'form-control-danger': form.errors.get('bandsintown_id') }"
                                        v-model="form.bandsintown_id"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('bandsintown_id')"
                                        v-text="form.errors.get('bandsintown_id')"
                                    >
                                    </span>

                                    <div>
                                        <small class="font-weight-bold">Displays events on your profile</small>
                                        <p class="small mb-0">
                                            To add or edit shows, use
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://artists.bandsintown.com/login"
                                            >
                                                Bandsintown for Artists.
                                            </a>
                                        </p>
                                        <p class="small mb-0">
                                            To find your Bands In Town profile, use
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.bandsintown.com/"
                                            >
                                                Bandsintown's search.
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="form-check mt-2" :class="{ 'has-danger': form.errors.get('remove_bandsintown') }">
                                <label class="form-check-label" for="remove_bandsintown">
                                    <input
                                        type="checkbox"
                                        name="name"
                                        class="form-check-input"
                                        :disabled="!form.bandsintown_id"
                                        :class="{ 'form-control-danger': form.errors.get('remove_bandsintown') }"
                                        v-model="form.remove_bandsintown"
                                    />
                                    Remove Bands In Town Calendar
                                </label>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('remove_bandsintown')"
                                    v-text="form.errors.get('remove_bandsintown')"
                                ></span>
                            </div>

                            <div class="form-check mt-2" :class="{ 'has-danger': form.errors.get('username') }">
                                <label class="form-check-label" for="private">
                                    <input
                                        type="checkbox"
                                        name="name"
                                        class="form-check-input"
                                        :class="{ 'form-control-danger': form.errors.get('private') }"
                                        v-model="form.private"
                                    />
                                    Keep my profile private
                                </label>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('private')"
                                    v-text="form.errors.get('private')"
                                ></span>
                            </div>
                        </div>

                        <div class="card-header">
                            <p><strong>Address Information</strong></p>
                        </div>

                        <div class="card-body px-4">
                            <address-fields
                                :form="form"
                                @change="form.clearErrors()"
                                :hiddenFields="['address']"
                                :requiredFields="['country', 'state', 'city', 'postal_code']"
                            >
                            </address-fields>
                        </div>

                        <div class="d-flex flex-row justify-content-center form-group">
                            <btn-state v-on:click="onSubmit" :state="form.state" class="btn btn-primary">
                                Save Profile
                            </btn-state>
                        </div>
                    </div>

                    <!-- Loading (remove this?) -->
                    <div v-else style="height: 100vh"></div>
                </div>
            </div>

            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import genreSelect from '../../components/genreSelect.vue';
import Form from '../../form/form.js';
import btnState from '../../components/btnState.vue';
import photoManage from '../../components/photoManage.vue';
import photoUpload from '../../components/photoUpload.vue';
import addressFields from '../../components/addressFields.vue';
import news from '../../components/news.vue';

export default {
    name: 'edit-profile',
    props: {
        uuid: {
            required: true,
            type: String,
        },
    },
    components: {
        genreSelect,
        news,
        btnState,
        addressFields,
        photoManage,
        photoUpload,
    },

    data() {
        return {
            profileFetchFailed: false,
            form: false,
        };
    },
    computed: {
        photoUrl() {
            return '/api/users/' + window.User.uuid + '/avatar';
        },
    },

    created() {
        if (!window.User.img_url) {
            swal({
                title: 'Set Profile Picture',
                text: 'Please set a profile picture.',
                icon: 'warning',
                buttons: [true, 'Upload'],
            }).then(willUpload => {
                if (willUpload) {
                    window.location = '/media-photos';
                }
            });
        }
        this.fetchProfile(this.uuid);
    },
    methods: {
        updateGenres(val) {
            this.form.genres = val;
        },

        fetchProfile(uuid) {
            var $v = this;
            axios
                .get('/api/users/' + uuid + '?include=genres')
                .then(function (response) {
                    $v.form = new Form(
                        Object.assign(
                            { email: window.User.email, remove_bandsintown: '', custom_city: '' },
                            response.data.data
                        )
                    );
                    $v.form.genres = response.data.data.genres.data.map(x => x.id);
                })
                .catch(function (error) {
                    $v.profileFetchFailed = error.data;
                });
        },

        onSubmit() {
            let $v = this;

            const website = $v.form.website;

            if (!(!website || website.startsWith('http') || website.startsWith('https'))) {
                $v.form.website = 'https://' + website;
            }

            let parsedBandsInTown = null;

            if ($v.form.bandsintown_id) {
                parsedBandsInTown = this.parseAndValidateBandsInTownId($v.form.bandsintown_id);

                if (!parsedBandsInTown) return;

                $v.form.bandsintown_id = parsedBandsInTown;
            }

            this.form
                .put('/api/users/' + this.uuid)
                .then(function (response) {
                    $v.response = response.data;
                })
                .catch(function (error) {
                    $v.response = error.data;
                });
        },

        parseAndValidateBandsInTownId(bandsInTownField) {
            if (bandsInTownField.includes(' ')) {
                this.form.errors.errors.bandsintown_id = ["Valid Bands In Town Ids cannot contain spaces"];
                this.form.state = 'error';
                return null;
            }

            if (
                !bandsInTownField.includes('http') &&
                !bandsInTownField.includes('www') &&
                !bandsInTownField.includes('.') &&
                !bandsInTownField.includes('/')
            ) {
                return bandsInTownField.split("-")[0]; // bandsintown uri presents slug as 1234-band-name when id is actually 1234
            }

            if (!bandsInTownField.includes('bandsintown.com/')) {
                this.form.errors.errors.bandsintown_id = ["Input must either be a Bands In Town ID or valid Bands In Town url"];
                this.form.state = 'error';
                return null;
            }

            const slug = bandsInTownField.split("/").slice(-1);

            return slug[0].split("-")[0];
        },
    },
};
</script>
