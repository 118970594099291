<template>
    <modal v-show="value" class="text-center">
        <div slot="body">
            <div v-if="guest">
                <h4>This is a deluxe feature.</h4>
                <p class="pb-3">Login to continue.</p>
                <a class="btn btn-primary mr-2" :href="'/login?intended=' + currentUri">Login</a>
                <button class="btn btn-secondary" @click="close">Not Now</button>
            </div>

            <div v-else>
                <h4>This is a deluxe feature.</h4>
                <p class="pb-3">Upgrade to Deluxe to continue.</p>
                <a class="btn btn-primary mr-2" href="/subscriptions">Upgrade</a>
                <a v-if="redirect" class="btn btn-secondary" :href="redirect"> Not Now </a>
                <button v-else class="btn btn-secondary" @click="close">Not Now</button>
            </div>
        </div>
    </modal>
</template>

<script>
import modal from './modal.vue';

export default {
    components: { modal },

    props: ['value', 'redirect'],

    computed: {
        guest() {
            return !window.User;
        },

        currentUri() {
            return encodeURIComponent(window.location.pathname);
        },
    },

    methods: {
        close() {
            this.$emit('input', false);
        },
    },
};
</script>
