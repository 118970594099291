<template>
    <div>
        <transition name="fade">
            <div class="mt-3 form-control-feedback alert alert-danger" role="alert" v-if="photoError !== ''">
                <button type="button" class="close" @click="clearAll">
                    <span aria-hidden="true">&times;</span>
                </button>
                <b>Error: </b>{{ photoError }}
            </div>
        </transition>
        <transition name="fade">
            <div class="mt-3 form-control-feedback alert alert-success" role="alert" v-if="photoSuccess">
                <button type="button" class="close" @click="photoSuccess = false">
                    <span aria-hidden="true">&times;</span>
                </button>
                <b>Success: </b> Your photo has been uploaded
            </div>
        </transition>
        <dropzone
            ref="dzId"
            class="mb-3"
            id="showAvailDz"
            :url="url"
            :options="dropzoneOptions"
            v-on:vdropzone-error="showError"
            v-on:vdropzone-success="showSuccess"
            enctype="multipart/form-data"
        ></dropzone>
        <p class="mb-0 small">Size Limit: 4MB</p>
        <p class="mb-0 small">Accepted Files: ['png','jpg','jpeg','bmp']</p>
        <p class="small">
            Disclaimer: By clicking Upload, I agree that the content being uploaded is not violating any copyrights.
        </p>
    </div>
</template>
<script>
import Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    props: {
        url: {
            required: true,
            type: String,
        },
    },
    components: { Dropzone },

    watch: {
        photoSuccess: function () {
            if (this.photoSuccess === true) {
                window.setInterval(
                    function () {
                        this.photoSuccess = false;
                    }.bind(this),
                    3000
                );
            }
        },
    },

    data() {
        return {
            photoError: '',
            photoSuccess: false,
            urlUpdated: false,
            dropzoneOptions: {
                maxFilesize: 4,
                acceptedFiles: '.jpg,.png,.jpeg,.gif,.bmp',
                url: this.url,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Drop files here to upload",
                headers: {
                    'X-CSRF-TOKEN': window.app.csrfToken,
                },
            },
        };
    },

    methods: {
        showSuccess(file, res) {
            this.$refs.dzId.removeFile(file);
            this.photoError = '';
            this.photoSuccess = true;
            bus.$emit('upload', res);
        },
        showError(file, error) {
            $('div.dz-error-message').addClass('invisible');
            $('div.dz-error-mark').addClass('invisible');
            this.$refs.dzId.dropzone.removeAllFiles();
            if (file.accepted === true && error.file) {
                this.photoError = error.file[0];
            } else {
                this.photoError = error;
            }
        },
        clearDzError(file) {
            this.$refs.dzId.dropzone.removeFile(file);
            this.photoSuccess = false;
            this.photoError = '';
        },
        clearAll() {
            this.photoError = '';
            this.photoSuccess = false;
            this.$refs.dzId.dropzone.removeAllFiles();
        },
    },
};
</script>
