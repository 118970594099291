<template>
    <div class="card mb-3">
        <div v-if="form && !loading">
            <div class="card-header d-flex justify-content-between">
                <h4 class="mb-0">
                    QuickPitch<span class="d-none d-md-inline"
                        >: The Booking Time Saver</span
                    >
                </h4>
                <button
                    @click="showTips = 1"
                    class="btn btn-sm btn-outline-primary"
                >
                    <i class="fa fa-question-circle pr-1"></i>Tips
                </button>
            </div>

            <quickpitch-tips v-model="showTips"></quickpitch-tips>

            <div class="card-body">
                <!-- If already submitted or sent -->
                <div v-if="form.submitted">
                    <div v-if="form.denied" class="alert alert-danger">
                        Denied by IOTM on
                        {{ form.date_denied | fromUTC("LLL") }}
                    </div>
                    <div v-else-if="form.approved" class="alert alert-success">
                        Approved and mailed by IOTM on
                        {{ form.date_sent | fromUTC("LLL") }}
                    </div>
                    <div v-else>
                        Submitted on {{ form.date_submitted | dateFormat }}
                    </div>
                </div>

                <div
                    @submit.prevent="updateQuickpitch"
                    @keydown="form.clearErrors($event.target.name)"
                >
                    <fieldset>
                        <!-- goodVenues -->
                        <div class="form-group">
                            <div
                                class="d-flex justify-content-between align-items-end"
                            >
                                <label for="venues"
                                    >To {{ goodCount }} Venues:</label
                                >
                                <button
                                    v-if="showRemoveOpenedVenues && opensCount"
                                    class="btn btn-sm btn-danger m-1"
                                    id="blockVenue"
                                    @click="removeVenuesWithOpenedEmails(uuid)"
                                >
                                    Remove Venues that Opened Last Email ({{
                                        opensCount
                                    }})
                                </button>
                                <button
                                    v-if="showRemoveOpenedVenuesSuccess"
                                    class="btn btn-sm btn-success m-1"
                                    id="blockVenue"
                                >
                                    {{ opensCount }} venues successfully removed
                                </button>
                            </div>
                            <venues
                                @removeVenue="removeVenue"
                                :venues="goodVenues"
                            ></venues>
                        </div>

                        <!-- blocked venues -->
                        <div v-show="blockedCount" class="form-group">
                            <div
                                class="pb-2 d-flex justify-content-between align-items-center"
                            >
                                <label for="blockedVenues"
                                    >{{ blockedCount }} venue{{
                                        blockedCount > 1 ? "s were" : " was"
                                    }}
                                    blocked by user.
                                </label>
                                <button
                                    @click.prevent="
                                        showingBlocked = !showingBlocked
                                    "
                                    class="btn btn-secondary"
                                >
                                    <i :class="toggleClass(showingBlocked)"></i>
                                    <span
                                        class="pl-1 d-none d-sm-inline"
                                        v-show="!showingBlocked"
                                    >
                                        Show Blocked
                                    </span>
                                </button>
                            </div>

                            <div v-show="showingBlocked">
                                <div v-show="blockedCount">
                                    <venues
                                        :deleteable="false"
                                        :venues="blockedVenues"
                                    ></venues>
                                </div>
                            </div>
                        </div>

                        <!-- recent venues -->
                        <div v-show="recentCount" class="form-group">
                            <div
                                class="pb-2 d-flex justify-content-between align-items-center"
                            >
                                <label for="recentVenues"
                                    >{{ recentCount }} venue{{
                                        recentCount > 1 ? "s were" : " was"
                                    }}
                                    emailed recently.
                                </label>
                                <button
                                    @click.prevent="
                                        showingRecent = !showingRecent
                                    "
                                    class="btn btn-secondary"
                                >
                                    <i :class="toggleClass(showingRecent)"></i>
                                    <span
                                        class="pl-1 d-none d-sm-inline"
                                        v-show="!showingRecent"
                                    >
                                        Show Recent
                                    </span>
                                </button>
                            </div>

                            <div v-show="showingRecent">
                                <div
                                    class="pb-2 d-flex justify-content-between align-items-center"
                                >
                                    <div
                                        class="form-check form-check-inline"
                                        v-if="canEmailAgain"
                                    >
                                        <label class="form-check-label">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="recentCheck"
                                                @click="addRecentAndUpdate"
                                            />
                                            Email Again
                                        </label>
                                    </div>
                                </div>
                                <venues
                                    :deleteable="false"
                                    :venues="recentVenues"
                                ></venues>
                            </div>
                        </div>

                        <!-- bad venues. no booker or not quickpitchable -->
                        <div v-show="badCount" class="form-group">
                            <div
                                class="pb-2 d-flex justify-content-between align-items-center"
                            >
                                <label for="recentVenues"
                                    >{{ badCount }} venue{{
                                        badCount > 1 ? "s have" : " has"
                                    }}
                                    disabled QuickPitch.</label
                                >
                                <button
                                    @click.prevent="
                                        showingExcluded = !showingExcluded
                                    "
                                    class="btn btn-secondary"
                                >
                                    <i
                                        :class="toggleClass(showingExcluded)"
                                    ></i>
                                    <span
                                        class="pl-1 d-none d-sm-inline"
                                        v-show="!showingExcluded"
                                    >
                                        Show Excluded
                                    </span>
                                </button>
                            </div>

                            <div v-show="showingExcluded">
                                <div v-show="badCount">
                                    <venues
                                        :deleteable="false"
                                        :venues="badVenues"
                                    ></venues>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="pr-2" for="from">From:</label
                            >{{ fromEmail }}
                        </div>

                        <div class="form-group">
                            <label for="pitch">Load Pitch:</label>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <select
                                        name="pitch"
                                        class="form-control"
                                        @input="handlePitchSelect"
                                        :value="form.pitch"
                                    >
                                        <option
                                            :value="null"
                                            key=""
                                            disabled
                                            :selected="!form.pitch"
                                        >
                                            None
                                        </option>
                                        <option
                                            v-for="pitch in pitches"
                                            :value="pitch.uuid"
                                            :key="pitch.uuid"
                                            :selected="
                                                pitch.uuid === form.pitch
                                            "
                                        >
                                            {{ pitch.pitch_subject }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <a href="/pitches" target="_blank"
                                        >Manage Saved Pitches</a
                                    >
                                </div>
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{
                                'has-danger el-form-item is-error':
                                    form.errors.get('date') ||
                                    form.errors.has('time') ||
                                    form.errors.has('timezone'),
                            }"
                        >
                            <label for="send_at">Send: </label>
                            <div class="form-check mb-3">
                                <input
                                    @click="clearDateTimeFields"
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="form.clear_send_at"
                                    name="disable_send_at"
                                    id="disable_send_at"
                                />
                                <label
                                    class="form-check-label"
                                    for="disable_send_at"
                                >
                                    Once approved
                                </label>
                            </div>
                            <div class="row my-2">
                                <flat-pickr
                                    class="col-lg-4 my-2 my-lg-0 form-control"
                                    :config="config"
                                    :disabled="form.clear_send_at"
                                    @input="getValidHours()"
                                    v-on:change="form.clearErrors('change')"
                                    placeholder="Select Date"
                                    v-model="form.date"
                                    name="date"
                                >
                                </flat-pickr>
                                <div class="col-lg-4">
                                    <select
                                        :disabled="form.clear_send_at"
                                        class="form-control"
                                        v-model="form.time"
                                    >
                                        <option disabled value="">
                                            Select hour.
                                        </option>
                                        <option
                                            v-for="hour in selectableHours"
                                            :value="hour"
                                        >
                                            {{ hour }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-4 my-2 my-lg-0">
                                    <select
                                        @change="getValidHours()"
                                        :disabled="form.clear_send_at"
                                        class="form-control"
                                        v-model="form.timezone"
                                    >
                                        <option value="" disabled>
                                            Select timezone.
                                        </option>
                                        <option
                                            v-for="(value, key) in timezones"
                                            :value="value['timezone']"
                                        >
                                            {{ key.toUpperCase() }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <span
                                class="form-control-feedback"
                                v-show="form.errors.has('time')"
                                v-text="form.errors.get('time')"
                            ></span>
                            <span
                                class="form-control-feedback"
                                v-show="form.errors.has('date')"
                                v-text="form.errors.get('date')"
                            ></span>
                        </div>

                        <dynamic-subject-field
                            ref="subjectField"
                            :errors="form.errors"
                            :subject="form.subject"
                            v-model="form.subject"
                            @input="handleSubjectChange"
                        ></dynamic-subject-field>

                        <div
                            class="form-group"
                            :class="{ 'has-danger': form.errors.get('body') }"
                        >
                            <label for="bio">Message *</label>
                            <trix
                                @input="handleBodyChange"
                                ref="vuetrix"
                                inputId="bodyTrix"
                                v-model="form.body"
                                placeholder=""
                                :body="form.body"
                                :class="{
                                    'form-control-danger':
                                        form.errors.get('body'),
                                }"
                            />
                            <small class="form-text font-weight-bold"
                                >**Links must be hyperlinked (appear blue and
                                clickable) in the body of the email in order for
                                them to track clicks.</small
                            >
                            <span
                                class="form-control-feedback"
                                v-show="form.errors.has('body')"
                                v-text="form.errors.get('body')"
                            ></span>
                        </div>

                        <!-- default quickpitch -->
                        <div class="form-group">
                            <div
                                v-if="pitches.length < this.pitchLimit"
                                class="form-check"
                            >
                                <input
                                    @input="setDefaultPitch"
                                    class="form-check-input"
                                    v-model="form.default"
                                    value="1"
                                    type="checkbox"
                                    name="default"
                                />
                                <label class="form-check-label">
                                    Set as default pitch
                                </label>
                            </div>
                        </div>
                    </fieldset>

                    <div class="form-group align-items-center">
                        <btn-state
                            :state="savePitchState"
                            @click="savePitch"
                            :disabled="savePitchDisabled"
                            class="btn btn-secondary btn-sm pr-2"
                        >
                            Add to saved pitches
                        </btn-state>
                        ({{ pitches.length + "/" + this.pitchLimit }} saved)
                    </div>

                    <!-- Actions -->
                    <div class="d-flex justify-content-end">
                        <a
                            href="/venues"
                            @click="updateQuickpitch"
                            :state="saveState"
                            class="btn mr-2 btn-secondary w-100"
                        >
                            <div v-if="!this.form.submitted">
                                <span class="d-none d-sm-inline">Add </span
                                >Venues
                            </div>
                            <div v-else>
                                <span class="d-none d-sm-inline">Back to </span
                                >Venues
                            </div>
                        </a>
                        <btn-state
                            :disabled="form.submitted"
                            @click="deleteQuickpitch"
                            :state="deleting"
                            btnClass="mr-2 btn-secondary w-100"
                        >
                            Cancel
                        </btn-state>
                        <btn-state
                            :disabled="form.submitted"
                            @click="updateQuickpitch"
                            :state="saveState"
                            btnClass="mr-2 btn-secondary w-100"
                        >
                            Save
                        </btn-state>
                        <btn-state
                            @click="submitQuickpitch"
                            :disabled="submitDisabled"
                            :state="submitState"
                            btnClass="btn-primary w-100"
                        >
                            <span class="d-none d-sm-inline">Preview / </span
                            >Submit
                        </btn-state>
                    </div>

                    <div
                        v-show="quickpitchFetchFailed"
                        class="alert alert-danger text-xs-center"
                    >
                        QuickPitch not found
                    </div>
                </div>
            </div>
        </div>

        <!-- Loading -->
        <div v-else class="container">
            <loading style="height: 60vh"></loading>
        </div>
    </div>
</template>

<script>
import dynamicSubjectField from "../../components/dynamicSubjectField.vue";
import moment from "moment-timezone";
import trix from "../../components/trix-wysiwyg.vue";
import Query from "../../query/query.js";
import Form from "../../form/form.js";
import btnState from "../../components/btnState.vue";
import venues from "../../components/quickpitch/venues.vue";
import loading from "../../components/loading.vue";
import quickpitchTips from "../../components/quickpitch/quickpitchTips.vue";

export default {
    name: "edit-quickpitch",
    props: {
        timezones: {
            type: Object,
            required: true,
        },
        hours: {
            type: Array,
            required: true,
        },
        pitchLimit: {
            default: 5,
        },

        uuid: {
            required: true,
            type: String,
        },
    },
    components: {
        trix,
        venues,
        loading,
        btnState,
        quickpitchTips,
        dynamicSubjectField,
    },

    data() {
        return {
            config: {
                dateFormat: "Y-m-d",
                disable: [
                    function (date) {
                        var tomorrow = moment()
                            .local()
                            .add(4, "hours")
                            .startOf("day");
                        var time = tomorrow.format("hh A");
                        if (date < tomorrow && time <= "9 PM") {
                        }
                    },
                ],
            },
            loading: true,
            query: new Query({
                include:
                    "venues,good_venues,bad_venues,recent_venues,blocked_venues,pitch,opens,timezone,time,date",
            }),
            showTips: false,
            showRemoveOpenedVenues: true,
            showRemoveOpenedVenuesSuccess: false,
            selectableHours: "",
            savePitchState: "none",
            quickpitchFetchFailed: false,
            pitch: {},
            pitches: [],
            form: new Form({
                venues: [],
                preview: false,
                send_at: "",
                send_at_timezone: "",
                date: "",
                time: "",
                timezone: "",
                default: null,
                pitch: "",
            }),
            showingExcluded: false,
            showingRecent: false,
            showingBlocked: false,
            venues: [],
            goodVenues: [],
            badVenues: [],
            recentVenues: [],
            blockedVenues: [],
            deleting: false,
            submitting: false,
            opens: [],
        };
    },

    created() {
        const vm = this;
        this.fetchQuickpitch().then(() => {
            this.fetchSavedPitches().then(() => {
                if (!vm.form.pitch && !vm.form.cloned) {
                    new Promise((resolve) => {
                        const pitch = this.pitches.find((p) => p.default);
                        if (pitch && !(vm.form.body && vm.form.subject)) {
                            resolve(pitch);
                        }
                    }).then((pitch) => {
                        this.loading = false;
                        this.loadPitch(pitch);
                    });
                }
            });
        });
    },

    mounted: function () {
        document.addEventListener("trix-initialize", function (event) {
            var buttonHTML =
                '<button type="button" class="trix-button underline" data-trix-attribute="underline" title="underline">U</button>';

            event.target.toolbarElement
                .querySelector(".trix-button-group--text-tools")
                .insertAdjacentHTML("beforeend", buttonHTML);
        });
    },

    computed: {
        canEmailAgain() {
            return window.User.impersonating || window.User.is_admin;
        },
        savePitchDisabled() {
            // reached limit || existing pitch || subject and body not filled
            const disabled =
                this.pitches.length >= this.pitchLimit ||
                this.form.pitch ||
                !(this.form.subject && this.form.body);

            // make it a bool
            return !!disabled;
        },

        submitDisabled() {
            return (
                this.goodCount < 1 ||
                this.form.subject === "" ||
                this.form.body === "" ||
                this.form.submitted
            );
        },

        badCount() {
            return this.badVenues ? this.badVenues.length : "";
        },

        recentCount() {
            return this.recentVenues ? this.recentVenues.length : "";
        },

        blockedCount() {
            return this.blockedVenues ? this.blockedVenues.length : "";
        },

        goodCount() {
            return this.goodVenues ? this.goodVenues.length : "";
        },

        opensCount() {
            const venueIds = this.opens.map((open) => {
                return open.quickpitch_venue_id;
            });

            const count = new Set(venueIds).size;

            return count ? count : "";
        },

        submitState() {
            if (this.submitting) {
                return this.form.state;
            }
        },

        saveState() {
            if (!this.submitting) {
                return this.form.state;
            }
        },

        fromEmail() {
            return window.User.email;
        },
    },

    methods: {
        handleBodyChange(event) {
            if (this.form.pitch && event.length) {
                let newBody = $(event).html(event).text().length;
                let pitchBody = $(this.pitch.body)
                    .html(this.pitch.body)
                    .text().length;
                let bodyHasChanges = newBody !== pitchBody;
                this.form.pitch = bodyHasChanges ? null : this.pitch.uuid;
                this.form.default = bodyHasChanges ? false : true;
            }
        },
        handleSubjectChange(event) {
            if (this.form.pitch && event.length) {
                let newSubject = $(event).html(event).text().length;
                let pitchSubject = $(this.pitch.subject)
                    .html(this.pitch.subject)
                    .text().length;
                let subjectHasChanges = newSubject !== pitchSubject;
                this.form.pitch = subjectHasChanges ? null : this.pitch.uuid;
                this.form.default = subjectHasChanges ? false : true;
            }
        },
        filterSelectableHours() {
            var timezone = this.form.timezone;
            var valid = moment().utc().add(4, "hours");
            return this.hours.filter((hour) => {
                return moment
                    .tz(
                        this.form.date + " " + hour,
                        "YYYY-MM-DD hh A",
                        timezone
                    )
                    .isSameOrAfter(valid);
            });
        },
        getValidHours() {
            const date = moment(this.form.date).tz(this.form.timezone);
            this.selectableHours = this.hours;
            if (date < moment().endOf("day")) {
                this.selectableHours = this.filterSelectableHours(this.hours);
            }
        },
        updateQpSubject(qp) {
            if (
                !qp.subject.startsWith("Re:") &&
                !qp.subject.startsWith("<div>Re:")
            ) {
                return qp.subject.replace("<div>", "<div>Re: ");
            }
            return qp.subject;
        },
        updateQpBody(qp) {
            var date = this.$options.filters.fromUTC(qp.updated_at, "LLL");
            return `<br><br>On ${date} ${window.User.email} wrote:<p><br>${qp.body}</p>`;
        },
        clearDateTimeFields() {
            this.form.clear_send_at = true;
            this.form.time = "";
            this.form.date = "";
        },
        setDefaultPitch() {
            if (!this.form.pitch) {
                return axios
                    .post("/api/pitches", {
                        body: this.form.body,
                        subject: this.form.subject,
                        default: true,
                    })
                    .then((res) => {
                        this.form.pitch_id = res.data.data.id;
                        this.form.pitch = res.data.data;
                        this.fetchSavedPitches();
                    });
            }
            axios
                .put("/api/pitches/" + this.form.pitch, {
                    body: this.form.body,
                    subject: this.form.subject,
                    default: true,
                })
                .then(() => {
                    this.fetchSavedPitches();
                });
        },

        fetchSavedPitches() {
            return axios.get("/api/pitches").then((res) => {
                this.pitches = res.data.data;
            });
        },

        fetchQuickpitch() {
            console.log(this.query.toString());
            return axios
                .get(
                    "/api/quickpitch/" + this.uuid + "?" + this.query.toString()
                )
                .then(this.parseQuickpitchResponse)
                .catch((error) => {
                    console.log(error);
                    this.quickpitchFetchFailed = error.data;
                });
        },

        removeVenue(venue) {
            this.goodVenues = this.goodVenues.filter((i) => {
                return i.uuid !== venue.uuid;
            });

            this.venues = this.venues.filter((i) => {
                return i.uuid !== venue.uuid;
            });

            this.form.venues = this.venues.map((i) => i.uuid);
            this.form.clearErrors();
        },

        async removeVenuesWithOpenedEmails(quickpitchUUID) {
            await axios.post("/api/quickpitch/remove-opened/" + quickpitchUUID);

            this.fetchQuickpitch();

            this.showRemoveOpenedVenues = false;
            this.showRemoveOpenedVenuesSuccess = true;
        },

        submitQuickpitch() {
            console.log(this.form);
            console.log(this.query.toString());
            this.submitting = true;
            this.form.preview = true;
            this.updateQuickpitch(true).then(() => {
                this.form.preview = false;
                if (this.form.state !== "error") {
                    window.location = "/quickpitch/" + this.uuid;
                }
            });
        },

        updateQuickpitch(submit) {
            if (!submit) {
                this.submitting = false;
            }

            return this.form
                .put(
                    "/api/quickpitch/" + this.uuid + "?" + this.query.toString()
                )
                .then(this.parseQuickpitchResponse)
                .catch((error) => {
                    this.response = error.data;
                });
        },

        addRecentAndUpdate(event) {
            this.form.add_recent = event.target.checked;
            this.updateQuickpitch();
        },

        deleteQuickpitch() {
            return axios.delete("/api/quickpitch/" + this.uuid).then(() => {
                this.deleting = "processing";
                window.location = "/venues";
            });
        },

        parseQuickpitchResponse(response) {
            const qp = response.data.data;
            this.venues = qp.venues.data;
            this.goodVenues = qp.good_venues.data;
            this.badVenues = qp.bad_venues.data;
            this.blockedVenues = qp.blocked_venues.data;
            this.recentVenues = qp.recent_venues.data;
            if (qp.opens) {
                this.opens = qp.opens.data;
            }
            const oldState = this.form.state;
            if (qp.pitch) {
                var pitch = qp.pitch.data;
                this.pitch = pitch;
            }
            if (qp.cloned) {
                this.form = new Form(
                    Object.assign({}, qp, {
                        terms: true,
                        pitch: null,
                        clear_send_at: true,
                        venues: this.venues.map((i) => i.uuid),
                        preview: false,
                        date: '',
                        time: '',
                        timezone: '',
                        subject: this.updateQpSubject(qp),
                        body: this.updateQpBody(qp),
                    })
                );
            } else {
                var tz = moment.tz.guess();
                if (qp.send_at_timezone) {
                    var tz = qp.send_at_timezone;
                }
                new Promise((resolve) => {
                    var timezone = Object.entries(this.timezones).find(
                        (zone) => {
                            return zone.find((z) => z.timezone === tz);
                        }
                    );
                    if (!timezone) {
                        var timezone = Object.entries(this.timezones).find(
                            (zone) => {
                                return zone.find(
                                    (z) => z.timezone === "America/New_York"
                                );
                            }
                        );
                    }
                    resolve(timezone);
                }).then((timezone) => {
                    var tz = timezone[1].timezone;
                    var sendDate = qp.send_at
                        ? moment.utc(qp.send_at).tz(tz).format("YYYY-MM-DD")
                        : null;
                    var sendTime = qp.send_at
                        ? moment.utc(qp.send_at).tz(tz).format("hh A")
                        : null;
                    this.form = new Form(
                        Object.assign({}, qp, {
                            date: sendDate,
                            time: sendTime,
                            timezone: tz,
                            clear_send_at: sendDate == null ? true : false,
                            venues: this.venues.map((i) => i.uuid),
                            preview: false,
                            default: pitch ? pitch.default : null,
                            pitch: pitch ? pitch.uuid : "",
                        })
                    );
                    this.selectableHours = this.filterSelectableHours(
                        this.hours
                    );
                    this.form.state = oldState;
                });
            }

            this.loading = false;
        },

        toggleClass(bool) {
            return {
                fa: true,
                "fa-plus": !bool,
                "fa-minus": bool,
            };
        },

        savePitch() {
            this.loading = true;
            this.savePitchState = "processing";
            return axios
                .post("/api/pitches", {
                    subject: this.form.subject,
                    body: this.form.body,
                    default: this.form.default,
                })
                .then((res) => {
                    this.form.pitch = res.data.data.uuid;
                    this.fetchSavedPitches();
                    this.savePitchState = "success";
                    this.loading = false;
                    window.setTimeout(() => {
                        this.savePitchState = "none";
                    }, 2000);
                })
                .catch((err) => {
                    this.savePitchState = "error";
                    this.loading = false;
                    window.setTimeout(() => {
                        this.savePitchState = "none";
                    }, 2000);
                });
        },

        handlePitchSelect(event) {
            new Promise((resolve) => {
                this.form.pitch = event.target.value;
                resolve(event.target.value);
            }).then((value) => {
                new Promise((resolve) => {
                    let pitch = this.pitches.find((p) => p.uuid === value);
                    resolve(pitch);
                }).then((pitch) => {
                    this.loadPitch(pitch);
                });
            });
        },

        loadPitch(pitch) {
            this.pitch = pitch;
            this.form.pitch = pitch.uuid;
            this.form.pitch_id = pitch.id;
            this.form.default = pitch.default;
            this.form.subject = pitch.subject;
            this.form.body = pitch.body;
            this.$refs.vuetrix.$refs.trixPreview.$refs.trix.value = pitch.body;
            this.$refs.subjectField.$refs.trixSubjectField.$refs.trix.value =
                pitch.subject;
            this.updateQuickpitch();
        },
    },
};
</script>
