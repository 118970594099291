<template>
    <transition name="modal">
        <div @click="$emit('close')" v-if="photo" class="modal-mask">
            <div class="modal-wrapper">
                <div class="preview-body">
                    <div class="card d-inline-block border-0">
                        <img class="img-fluid preview-img" :src="photo.url + '?w=1000&h=1000'" />

                        <div v-if="!hidecontrols" class="mt-2">
                            <a class="btn btn-secondary btn-sm mr-2" @click.stop="$emit('prev')">
                                <i class="fa fa-arrow-left"></i>
                                Prev
                            </a>
                            <a class="btn btn-secondary btn-sm" @click.stop="$emit('next')">
                                Next
                                <i class="fa fa-arrow-right"></i>
                            </a>
                        </div>

                        <div class="d-flex justify-content-between">
                            <div class="m-3 text-left">
                                <p v-if="photo.title"><b class="pr-2">Title:</b>{{ photo.title }}</p>
                                <p v-if="photo.venue">
                                    <b class="pr-2">Venue:</b>
                                    <a :href="'/venues/' + photo.venue_slug"> {{ photo.venue }}</a>
                                </p>
                                <p><b class="pr-2">Added:</b>{{ photo.created_at | fromUTC('MMMM Do, YYYY') }}</p>
                                <p>
                                    <b class="pr-2">By:</b>
                                    <a :href="'/users/' + photo.user">{{ photo.user }}</a>
                                </p>
                                <p v-if="photo.caption"><b class="pr-2">Description:</b>{{ photo.caption }}</p>
                            </div>

                            <div>
                                <a
                                    v-if="isOwner"
                                    :href="'/photos/' + photo.uuid + '/edit'"
                                    class="m-3 align-self-start btn-sm btn btn-outline-primary"
                                >
                                    <i class="fa fa-pencil"></i>
                                    Edit
                                </a>

                                <button
                                    @click="$emit('close')"
                                    class="m-3 align-self-start btn-sm btn btn-outline-primary"
                                >
                                    <i class="fa fa-close"></i>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['hidecontrols', 'photo'],

    computed: {
        isOwner() {
            return this.photo.user_uuid === window.User.uuid;
        },
    },
};
</script>

<style>
.preview-img {
    max-width: 100vw;
    max-height: 70vh;
}
.img-next {
    height: 100%;
    width: 50%;
    position: relative;
    /*background-color: rgba(0, 0, 0, .5);*/
    /*z-index: 9999;*/
}
.img-prev {
    height: 100%;
    width: 50%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.modal-mask {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.preview-body {
    max-width: 1000px;
    text-align: center;
    margin: 0px auto;
    transition: all 0.3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
