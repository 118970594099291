<template>
    <div class="card">
        <div
            :class="dark ? 'bg-dark text-light' : ''"
            class="card-header d-flex justify-content-between align-items-center"
        >
            <h4 class="mb-0">Photos {{ title ? 'for ' + title : '' }}</h4>

            <button v-if="type === 'venue'" @click="handleAddButton" class="btn btn-primary btn-sm">
                <i class="fa fa-plus pr-2"></i>
                Add
            </button>

            <a v-else-if="type == 'user-profile' && isOwner" href="/media-photos" class="btn btn-primary btn-sm">
                <i class="fa fa-plus pr-2"></i>
                Add
            </a>

            <modal v-if="showModal">
                <div class="text-center text-dark" slot="body">
                    <photo-upload :url="fetchUrl"></photo-upload>
                    <button class="btn btn-link" @click="showModal = false">Back</button>
                </div>
            </modal>
        </div>
        <div class="card-body">
            <div v-if="photos.length" class="text-center">
                <div class="flex-grid">
                    <img
                        class="flex-item"
                        v-for="(v, index) in photos"
                        :key="v.uuid"
                        @click="previewPhoto(index)"
                        :src="v.url + '?w=300&h=300&fit=crop'"
                    />
                </div>

                <a class="btn btn-link" v-show="moreLink" :href="moreLink">View All</a>
            </div>

            <div v-else-if="ready" class="text-center alert alert-info">No photos have been added.</div>

            <preview
                @prev="previewPhoto(activePhotoIndex - 1)"
                @next="previewPhoto(activePhotoIndex + 1)"
                @close="activePhotoIndex = null"
                :photo="activePhoto"
            ></preview>
        </div>
    </div>
</template>

<script>
import photoUpload from '../photoUpload.vue';
import modal from '../modal.vue';
import preview from '../photoPreview.vue';

export default {
    name: 'photos',

    components: { photoUpload, modal, preview },

    props: {
        uuid: {
            type: String,
        },
        type: {
            type: String,
        },
        filter: {
            type: String,
        },
        title: {
            type: String,
        },
        dark: {
            type: Boolean,
            defaul: false,
        },
        limit: {
            default: false,
        },
    },

    data() {
        return {
            photos: [],
            uploadedPhoto: false,
            activePhotoIndex: null,
            showModal: false,
            ready: false,
        };
    },
    created() {
        this.fetchPhotos();

        bus.$on('upload', res => {
            this.showModal = false;
            this.activePhoto = res.data;
            this.fetchPhotos();
        });
    },

    computed: {
        activePhoto() {
            if (this.activePhotoIndex >= 0) {
                return this.photos[this.activePhotoIndex];
            }
        },

        isOwner() {
            return window.User && window.User.uuid === this.uuid;
        },

        fetchUrl() {
            switch (this.type) {
                case 'band-avail':
                    return '/api/band-availabilities/' + this.uuid + '/photos?';
                case 'venue-avail':
                    return '/api/venue-availabilities/' + this.uuid + '/photos?';
                case 'classified':
                    return '/api/classifieds/' + this.uuid + '/photos?';
                case 'user-profile':
                    return '/api/users/' + this.uuid + '/photos?';
                case 'venue':
                    return '/api/venues/' + this.uuid + '/photos?';
                default:
                    return 'api/photos' + this.homeFilter;
            }
        },

        homeFilter() {
            if (this.filter === 'Local New') {
                return '?radius=100';
            } else if (this.filter === 'Local Popular') {
                return '?radius=100&sort_by=views';
            }

            return '?';
        },

        moreLink() {
            if (this.photos.length == this.limit && (this.type === 'venue' || this.type === 'user-profile')) {
                return window.location.href + '/photos';
            }
            return false;
        },
    },

    watch: {
        filter() {
            this.fetchPhotos();
        },
    },

    methods: {
        handleAddButton() {
            if (window.User) {
                this.showModal = true;
            } else {
                window.location = '/login?intended=' + window.location.pathname;
            }
        },

        previewPhoto(i) {
            if (typeof this.photos[i] === 'undefined') {
                this.activePhotoIndex = null;
                return;
            }
            this.activePhotoIndex = i;
            axios.post('/api/photos/' + this.photos[i].uuid + '/views');
        },

        fetchPhotos() {
            axios.get(this.fetchUrl + (this.limit ? '&limit=' + this.limit : '')).then(response => {
                this.photos = response.data.data;
                this.ready = true;
            });
        },
    },
};
</script>

<style>
.flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.flex-item {
    margin: 5px;
    width: 130px;
    height: 130px;
    cursor: pointer;
}
</style>
