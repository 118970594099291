<template>
    <form @submit.prevent="$emit('queryUpdate')" @change="handleInput" class="mb-2">
        <!-- Show Search Only Default -->
        <div class="form-group">
            <input
                @input="query.sort_by = 'relevance'"
                v-model="query.q"
                class="form-control"
                :placeholder="searchPlaceholder"
            />
        </div>

        <!-- Location -->
        <address-filters
            @input="$emit('queryUpdate')"
            :countries_with="availtype + '_availabilities'"
            :query="query"
        ></address-filters>

        <!-- Sort -->
        <div class="row">
            <!-- Genre -->
            <div class="col-sm-4 form-group">
                <select @input="handleGenreInput" v-model="query.genre" class="form-control">
                    <option v-for="g in genres" :value="g.id" v-text="g.genre"></option>
                </select>
            </div>
            <div class="d-flex col-sm-8 form-group justify-content-center">
                <a @click.prevent="$emit('toggleOrder')" class="d-flex align-items-center mr-1 btn btn-secondary">
                    <i v-if="query.sort_order === 'ascending'" class="fa fa-sort-amount-asc"></i>
                    <i v-else class="fa fa-sort-amount-desc"></i>
                    Sort
                </a>
                <select v-model="query.sort_by" class="form-control">
                    <option value="date">Availability Date</option>
                    <option value="created_at">Date Added</option>
                    <option v-if="showDistance" value="distance">Distance</option>
                    <option v-if="query.q" value="relevance">Relevance</option>
                </select>
            </div>
        </div>
    </form>
</template>

<script>
/* global bus location */
import addressFilters from '../addressFilters.vue';

export default {
    name: 'availabilities-filter',

    components: { addressFilters },

    props: {
        availtype: {
            type: String,
            default: 'venue',
        },
        query: {
            type: Object,
            required: true,
        },

        showDistance: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        searchPlaceholder() {
            return this.availtype === 'venue' ? 'Search title, city, state' : 'Search title, band name, city, state';
        },
    },

    data() {
        return {
            genres: [],
        };
    },

    created() {
        axios.get('/api/genres?allgenres=true').then(response => {
            this.genres = response.data.data;
        });
    },

    methods: {
        handleGenreInput(e) {
            this.query.genre = e.target.value;
            this.$emit('queryUpdate');
        },

        handleInput(e) {
            const vm = this;
            // instead of this.$nextTick
            setTimeout(() => {
                vm.$emit('queryUpdate');
            });
        },
    },
};
</script>
