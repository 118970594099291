<template>
    <div class="card" v-if="!empty || type === 'venue'">
        <div v-if="type === 'venue' && uuid" class="card-header d-flex justify-content-between">
            <h4 class="m-0">Photos</h4>
            <button @click="showModal = true" class="btn btn-outline-primary btn-sm">
                <i class="fa fa-plus pr-2"></i>
                Add
            </button>

            <modal v-if="showModal">
                <div class="text-center" slot="body">
                    <photo-upload v-if="uuid" :url="`/api/venues/${uuid}/photos`"></photo-upload>
                    <button class="btn-link" @click="showModal = false">Back</button>
                </div>
            </modal>
        </div>

        <div v-else class="card-header">
            <h4 class="m-0">Photos</h4>
        </div>

        <div class="card-body" :class="{ 'bg-dark': !empty }">
            <div v-if="empty" class="text-center alert alert-info">No photos have been added</div>

            <div v-else id="photoCarousel" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li
                        v-for="(photo, index) in photos"
                        data-target="#photoCarousel"
                        data-slide-to="photo"
                        :class="{ active: photo === photos[0] }"
                    ></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div
                        class="carousel-item"
                        v-for="(photo, index) in photos"
                        :class="{ active: photo === photos[0] }"
                    >
                        <img
                            @click="view = photo.url"
                            id="carouselImage"
                            class="pointer d-block img-thumbnail img-fluid mx-auto"
                            :src="photo.url + '?w=300&h=300&fit=crop'"
                        />
                    </div>
                </div>
                <a
                    v-if="photos.length > 1"
                    class="carousel-control-prev ml-5"
                    href="#photoCarousel"
                    role="button"
                    data-slide="prev"
                >
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a
                    v-if="photos.length > 1"
                    class="carousel-control-next mr-5"
                    href="#photoCarousel"
                    role="button"
                    data-slide="next"
                >
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>

                <photo-preview v-model="view"></photo-preview>
            </div>
        </div>
    </div>
</template>

<script>
import photoUpload from '../photoUpload.vue';
import modal from '../modal.vue';
import photoPreview from '../photoPreview.vue';

export default {
    name: 'photos',
    props: {
        uuid: {
            type: String,
        },
        type: {
            type: String,
        },
    },

    components: { modal, photoUpload, photoPreview },

    data() {
        return {
            photos: [],
            view: '',
            showModal: false,
        };
    },
    created() {
        this.fetchPhotos();

        bus.$on('upload', () => {
            this.showModal = false;
            this.fetchPhotos();
        });
    },
    computed: {
        fetchUrl() {
            switch (this.type) {
                case 'band-avail':
                    return '/api/band-availabilities/' + this.uuid + '/photos';
                case 'venue-avail':
                    return '/api/venue-availabilities/' + this.uuid + '/photos';
                case 'classified':
                    return '/api/classifieds/' + this.uuid + '/photos';
                case 'user-profile':
                    return '/api/users/' + this.uuid + '/photos';
                case 'venue':
                    return '/api/venues/' + this.uuid + '/photos';
                default:
                    return 'api/photos';
            }
        },

        empty() {
            return !this.photos.length;
        },
    },
    methods: {
        fetchPhotos() {
            axios.get(this.fetchUrl).then(response => {
                this.photos = response.data.data;
            });
        },
    },
};
</script>
