<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <!-- Desktop filters -->

                        <div class="btn-group d-none d-lg-inline mr-2">
                            <div class="btn-group">
                                <button type="button" class="btn btn-secondary mr-2"
                                    @click="(sortDesc = !sortDesc), updateQuery()">
                                    <i :class="filterButton"></i>
                                </button>
                                <button type="button" data-toggle="dropdown" class="btn btn-secondary dropdown-toggle">
                                    {{ userFilter | truncate(10, '...') }}
                                </button>
                                <div class="dropdown-menu">
                                    <a @click.prevent="(userFilter = filter), updateQuery()" class="dropdown-item"
                                        v-for="filter in userFilters" href="#">
                                        {{ filter }}
                                    </a>
                                </div>
                                <div class="dropdown d-none d-lg-inline mx-2">
                                    <button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" type="button">
                                        {{ selectedFilter | truncate(10, '...') }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item btn" v-for="filter in filters"
                                            @click="(selectedFilter = filter), fetchData(filter)">
                                            {{ filter }}
                                        </a>
                                    </div>
                                </div>
                                <div class="dropdown d-none d-lg-inline">
                                    <button class="btn btn-secondary dropdown-toggle mr-2" data-toggle="dropdown"
                                        type="button">
                                        {{ selectedFollowerFilter | truncate(10, '...') }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item btn" v-for="filter in followersFilter" @click="
                                            (selectedFollowerFilter = filter), fetchUsers(filter), (aggregated = [])
                                            ">
                                            {{ filter }}
                                        </a>
                                    </div>
                                </div>
                                <button class="btn btn-outline-primary d-none d-lg-inline"
                                    v-if="selectedFilter === 'Following' && selectedFollowerFilter === 'All'"
                                    v-bind:disabled="aggregated[0] === undefined" @click="unfollowAggregate">
                                    Unfollow
                                </button>
                                <button class="btn btn-outline-danger d-none d-lg-inline"
                                    v-if="selectedFilter === 'Followers' && selectedFollowerFilter === 'All'"
                                    v-bind:disabled="aggregated[0] === undefined" @click="blockAggregate">
                                    Block
                                </button>
                                <button class="btn btn-outline-success d-none d-lg-inline"
                                    v-if="selectedFollowerFilter === 'Pending'"
                                    v-bind:disabled="aggregated[0] === undefined" @click="approveAggregate">
                                    Approve
                                </button>
                                <button class="btn btn-outline-primary d-none d-lg-inline"
                                    v-if="selectedFollowerFilter === 'Blocked'"
                                    v-bind:disabled="aggregated[0] === undefined" @click="unblockAggregate">
                                    Unblock
                                </button>
                            </div>
                        </div>
                        <input @keydown.enter="fetchData()" @keydown.delete="clearQuery()" @keyup.delete="clearQuery()"
                            v-model="query.q" type="text" class="form-control ml-auto mr-2 col-xl-5"
                            placeholder="Search for users..." />
                        <button class="btn btn-primary" @click.prevent="fetchData()">Search</button>
                    </div>

                    <!-- Mobile filters -->

                    <div class="mt-2 d-flex flex-row justify-content-center d-lg-none">
                        <span class="mr-2">
                            <button class="btn btn-outline-primary" @click="unfollowAggregate"
                                v-bind:disabled="aggregated[0] === undefined"
                                v-if="selectedFilter === 'Following' && selectedFollowerFilter === 'All'">
                                Unfollow
                            </button>
                            <button class="btn btn-outline-danger" @click="blockAggregate"
                                v-bind:disabled="aggregated[0] === undefined"
                                v-if="selectedFilter === 'Followers' && selectedFollowerFilter === 'All'">
                                Block
                            </button>
                            <button class="btn btn-outline-success" @click="approveAggregate"
                                v-bind:disabled="aggregated[0] === undefined"
                                v-if="this.selectedFollowerFilter === 'Pending'">
                                Approve
                            </button>
                            <button class="btn btn-outline-primary" @click="unblockAggregate"
                                v-bind:disabled="aggregated[0] === undefined"
                                v-if="this.selectedFollowerFilter === 'Blocked'">
                                Unblock
                            </button>
                        </span>
                        <div class="btn-group mr-1">
                            <button type="button" data-toggle="dropdown" class="btn btn-secondary dropdown-toggle">
                                {{ userFilter | truncate(10, '...') }}
                            </button>
                            <button type="button" class="btn btn-secondary ml-2"
                                @click="(sortDesc = !sortDesc), updateQuery()">
                                <i :class="filterButton"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a @click.prevent="(userFilter = filter), updateQuery()" class="dropdown-item"
                                    v-for="filter in userFilters" href="#">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>
                        <div class="dropdown d-none d-lg-inline" v-if="selectedFilter === 'Followers'">
                            <button class="btn btn-secondary dropdown-toggle mr-2" data-toggle="dropdown" type="button">
                                {{ selectedFollowerFilter | truncate(10, '...') }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item btn" v-for="filter in followersFilter"
                                    @click="(selectedFollowerFilter = filter), fetchUsers(filter), (aggregated = [])">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button class="btn btn-secondary d-none d-lg-inline dropdown-toggle mr-2" data-toggle="dropdown"
                                type="button">
                                {{ this.selectedFilter | truncate(10, '...') }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item btn" v-for="filter in filters"
                                    @click="(selectedFilter = filter), fetchData(filter), (aggregated = [])">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <span class="d-flex flex-row justify-content-center d-md-none mt-2">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle mr-2" data-toggle="dropdown" type="button">
                                {{ this.selectedFilter | truncate(10, '...') }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item btn" v-for="filter in filters"
                                    @click="(selectedFilter = filter), fetchData(filter), (aggregated = [])">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>
                        <div class="dropdown" v-if="selectedFilter === 'Followers'">
                            <button class="btn btn-secondary dropdown-toggle mr-2" data-toggle="dropdown" type="button">
                                {{ selectedFollowerFilter | truncate(10, '...') }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item btn" v-for="filter in followersFilter"
                                    @click="(selectedFollowerFilter = filter), fetchUsers(filter), (aggregated = [])">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>
                    </span>

                    <!-- Succcess Messages -->

                    <div class="card-body">
                        <span v-if="options.length">
                            <transition name="fade">
                                <div class="alert alert-success" role="alert" v-if="blockedAggregate">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <span v-if="selectedFilter === 'Followers' && selectedFollowerFilter === 'All'">
                                        <strong> Success: </strong>
                                        users blocked.
                                    </span>
                                    <span v-if="selectedFilter === 'Following' && selectedFollowerFilter === 'All'">
                                        <strong> Success: </strong>
                                        users unfollowed.
                                    </span>
                                    <span v-if="selectedFollowerFilter === 'Pending'">
                                        <strong> Success: </strong>
                                        follow requests approved.
                                    </span>
                                    <span v-if="selectedFollowerFilter === 'Blocked'">
                                        <strong> Success: </strong>
                                        users unblocked.
                                    </span>
                                </div>
                            </transition>
                            <transition name="fade">
                                <div class="alert alert-success" role="alert" v-if="blockedUser">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {{ blockedUserInfo.message }}
                                </div>
                            </transition>
                            <transition name="fade">
                                <div class="alert alert-success" role="alert" v-if="unblockedUser">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {{ unblockedUserInfo.message }}
                                </div>
                            </transition>
                            <transition name="fade">
                                <div class="alert alert-success" role="alert" v-if="approvedUser">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {{ approvedUserInfo.message }}
                                </div>
                            </transition>

                            <!-- User list -->

                            <ul class="list-group" v-if="options">
                                <li class="list-group-item d-flex flex-row justify-content-start">
                                    <input type="checkbox" @click="aggregateAll" />
                                    <small class="ml-2"> Name </small>
                                    <small class="ml-auto"> Date Followed </small>
                                </li>
                                <li class="list-group-item d-flex flex-row justify-content-start"
                                    :class="[isToggled(index) ? 'bg-primary' : '']" v-for="(option, index) in options"
                                    @click="toggle(index)">
                                    <input type="checkbox" :checked="aggregated.includes(option)"
                                        @click.stop="aggregate(option)" />
                                    <span class="ml-2" :class="[isToggled(index) ? 'text-white' : '']">
                                        {{ option.username | truncate(15, '...') }}
                                    </span>
                                    <div v-if="!isToggled(index)">
                                        <span class="mr-auto">
                                            <img class="navbar-avatar img-circle ml-1" v-if="option.img_url"
                                                :src="option.img_url + '?w=300&h=300&fit=crop'" />
                                            <img class="navbar-avatar img-circle ml-1" v-else
                                                src="../../../../../public/img/user.svg" />
                                        </span>
                                    </div>
                                    <span class="mx-auto" v-else>
                                        <span>
                                            <small class="text-white"> Profile </small>
                                            <button class="btn btn-link rounded-circle bg-white listButton"
                                                @click="visitProfile(option)">
                                                <i class="fa fa-user-o"></i>
                                            </button>
                                        </span>
                                        <span class="d-none d-lg-inline ml-auto"
                                            v-if="selectedFilter === 'Followers' && selectedFollowerFilter === 'All'">
                                            <small class="text-white"> Block </small>
                                            <button class="btn btn-link rounded-circle bg-white listButton"
                                                @click.stop="blockUser(option)">
                                                <i class="fa fa-times-circle"></i>
                                            </button>
                                        </span>
                                        <span class="d-none d-lg-inline ml-auto"
                                            v-if="selectedFilter === 'Following' && selectedFollowerFilter === 'All'">
                                            <small class="text-white"> Unfollow </small>
                                            <button class="btn btn-link rounded-circle bg-white listButton"
                                                @click.stop="unfollowUser(option)">
                                                <i class="fa fa-times-circle"></i>
                                            </button>
                                        </span>
                                        <span class="d-none d-lg-inline ml-auto"
                                            v-if="selectedFollowerFilter === 'Pending'">
                                            <small class="text-white"> Approve </small>
                                            <button class="btn btn-link rounded-circle bg-white listButton"
                                                @click.stop="approveUser(option)">
                                                <i class="fa fa-check-circle"></i>
                                            </button>
                                        </span>
                                        <span class="d-none d-lg-inline ml-auto"
                                            v-if="selectedFollowerFilter === 'Blocked'">
                                            <small class="text-white"> Unblock </small>
                                            <button class="btn btn-link rounded-circle bg-white listButton"
                                                @click.stop="unblockUser(option)">
                                                <i class="fa fa-times-circle"></i>
                                            </button>
                                        </span>
                                    </span>
                                    <span v-if="isToggled(index)">
                                        <span v-if="selectedFilter === 'Following' && option.date_followed"
                                            class="mr-auto d-md-none">
                                            <small class="text-white">
                                                Since: {{ option.date_followed.data.created_at }}
                                            </small>
                                        </span>
                                        <span v-if="selectedFilter === 'Followers' && option.date_user_followed"
                                            class="mr-auto d-md-none">
                                            <small class="text-white">
                                                Since: {{ option.date_user_followed.data.created_at }}
                                            </small>
                                        </span>
                                    </span>
                                    <span class="d-md-none mr-auto" v-if="!isToggled(index)">
                                        <span v-if="selectedFilter === 'Following' && option.date_followed">
                                            <small> Since: {{ option.date_followed.data.created_at }} </small>
                                        </span>
                                        <span v-if="selectedFilter === 'Followers' && option.date_user_followed">
                                            <small> Since: {{ option.date_user_followed.data.created_at }} </small>
                                        </span>
                                    </span>
                                    <span class="d-none d-md-inline ml-auto" v-if="!isToggled(index)">
                                        <span v-if="selectedFilter === 'Following' && option.date_followed">
                                            <small> Since: {{ option.date_followed.data.created_at }} </small>
                                        </span>
                                        <span v-if="selectedFilter === 'Followers' && option.date_user_followed">
                                            <small> Since: {{ option.date_user_followed.data.created_at }} </small>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </span>

                        <!-- No follows -->

                        <div v-if="!options.length && selectedFilter === 'Following' && selectedFollowerFilter === 'All'">
                            <div class="mb-0 alert alert-info">No users found.</div>
                        </div>
                        <div v-else-if="!options.length && selectedFilter === 'Followers' && selectedFollowerFilter === 'All'
                            ">
                            <div class="mb-0 alert alert-info">No users found.</div>
                        </div>
                        <div v-if="!options.length && selectedFollowerFilter === 'Pending'">
                            <div class="mb-0 alert alert-info">No pending follow requests found.</div>
                        </div>
                        <div v-else-if="!options.length && selectedFollowerFilter === 'Blocked'">
                            <div class="mb-0 alert alert-info">No blocked users found.</div>
                        </div>
                    </div>

                    <!-- pagination -->

                    <pagination class="mb-2" v-if="options.length" :pagination="pagination" :loading="isLoading"
                        @pageChange="updatePage"></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from '../../../query/query.js';
import pagination from '../../../components/pagination.vue';

export default {
    components: {
        Query,
        pagination,
    },
    data() {
        return {
            query: new Query({
                q: '',
                page: '',
                follow_type: 'users',
                sort_by: 'username',
                sort_order: 'descending',
                include: 'date_followed, date_user_followed',
            }),
            allSelected: false,
            aggregated: [],
            approvedUser: false,
            approvedUserInfo: [],
            blockedUser: false,
            blockedAggregate: false,
            blockedUserInfo: [],
            unblockedUser: false,
            unblockedUserInfo: [],
            value: [],
            filters: ['Followers', 'Following'],
            followersFilter: ['All', 'Pending', 'Blocked'],
            sortDesc: true,
            isLoading: false,
            toggled: [],
            options: [],
            userFilter: 'Alphabetic',
            userFilters: ['Alphabetic', 'Date Followed'],
            selectedFollowerFilter: 'All',
            selectedFilter: 'Following',
            pagination: {
                current_page: 1,
            },
            toggleOptions: false,
            user: window.User,
        };
    },
    created() {
        this.query.parse(location.search);
        this.fetchData();
    },
    methods: {
        aggregateAll() {
            if (this.allSelected) {
                this.aggregated = [];
                this.allSelected = false;
            } else {
                this.options.forEach(function (element) {
                    this.aggregated.push(element);
                }, this);
                this.allSelected = true;
            }
        },
        clearQuery() {
            if (this.query.q === '' || this.query.q === ' ') {
                this.fetchData();
            }
        },
        fetchUsers(filter) {
            switch (filter) {
                case 'Blocked':
                    axios
                        .get('/api/users/' + this.user.uuid + '/blocked-users/?' + this.query.toString())
                        .then(response => {
                            this.options = response.data.data;
                            this.pagination = response.data.meta.pagination;
                        });
                    break;
                case 'Pending':
                    axios
                        .get('/api/users/' + this.user.uuid + '/pending-followers' + '/?' + this.query.toString())
                        .then(response => {
                            this.options = response.data.data;
                            this.pagination = response.data.meta.pagination;
                        });
                    break;
                case 'All':
                    this.fetchData();
                    break;
            }
        },
        fetchData() {
            if (this.selectedFilter === 'Following') {
                this.selectedFollowerFilter = 'All';
                axios.get('/api/users/' + this.user.uuid + '/following/?' + this.query.toString()).then(response => {
                    this.options = response.data.data;
                    this.pagination = response.data.meta.pagination;
                });
            } else if (this.selectedFilter === 'Followers') {
                if (this.selectedFollowerFilter !== 'All') {
                    this.fetchUsers(this.selectedFollowerFilter);
                } else {
                    axios
                        .get('/api/users/' + this.user.uuid + '/followers/?' + this.query.toString())
                        .then(response => {
                            this.options = response.data.data;
                            this.pagination = response.data.meta.pagination;
                        });
                }
            }
        },
        visitProfile(option) {
            window.location.assign('/users/' + option.username);
        },
        sendMessage() {
            window.location.assign('/messages');
        },
        approveAggregate() {
            this.aggregated.forEach(function (element) {
                axios.get('/api/users/' + element.uuid + '/approve-follower').then(() => {
                    this.toggled = [];
                    this.fetchUsers(this.selectedFollowerFilter);
                });
            }, this);
            this.aggregated = [];
            this.$nextTick(() => {
                this.blockedAggregate = true;
                this.toggleOptions = false;
            });
        },
        blockAggregate() {
            this.aggregated.forEach(function (element) {
                axios.post('/api/users/' + element.uuid + '/block').then(() => {
                    this.toggled = [];
                    this.fetchUsers(this.selectedFollowerFilter);
                });
            }, this);
            this.aggregated = [];
            this.$nextTick(() => {
                this.blockedAggregate = true;
                this.toggleOptions = false;
            });
        },
        unfollowAggregate() {
            this.toggled = [];
            this.aggregated.forEach(function (element) {
                axios.delete('/api/users/' + element.uuid + '/unfollow').then(() => {
                    this.fetchData();
                });
            }, this);
            this.aggregated = [];
            this.$nextTick(() => {
                this.blockedAggregate = true;
                this.toggleOptions = false;
            });
        },
        unblockAggregate() {
            this.aggregated.forEach(function (element) {
                axios.delete('/api/users/' + element.uuid + '/unblock').then(() => {
                    this.toggled = [];
                    this.fetchData();
                });
            }, this);
            this.aggregated = [];
            this.$nextTick(() => {
                this.blockedAggregate = true;
                this.toggleOptions = false;
            });
        },
        unblockUser(option) {
            axios.delete('/api/users/' + option.uuid + '/unblock').then(response => {
                this.unblockedUser = true;
                this.unblockedUserInfo = response.data.success;
                this.fetchUsers('Blocked');
            });
            this.$nextTick(() => {
                this.toggleOptions = false;
                this.toggled = [];
            });
        },
        unfollowUser(option) {
            axios.delete('/api/users/' + option.uuid + '/unfollow').then(response => {
                this.blockedUser = true;
                this.blockedUserInfo = response.data.success;
                this.fetchData();
            });
            this.$nextTick(() => {
                this.toggleOptions = false;
                this.toggled = [];
            });
        },
        approveUser(option) {
            axios.get('/api/users/' + option.uuid + '/approve-follower').then(response => {
                this.approvedUser = true;
                this.approvedUserInfo = response.data.success;
                this.fetchUsers('Pending');
            });
            this.$nextTick(() => {
                this.toggleOptions = false;
                this.toggled = [];
            });
        },
        blockUser(option) {
            axios.post('/api/users/' + option.uuid + '/block').then(response => {
                this.blockedUser = true;
                this.blockedUserInfo = response.data.success;
                this.fetchData();
            });
            this.$nextTick(() => {
                this.toggleOptions = false;
                this.toggled = [];
            });
        },
        updateQuery() {
            if (this.sortDesc === true) {
                this.query.sort_order = 'descending';
            } else if (this.sortDesc === false) {
                this.query.sort_order = 'ascending';
            }

            if (this.userFilter === 'Date Followed') {
                this.query.sort_by = 'date_followed';
            } else if (this.userFilter === 'Alphabetic') {
                this.query.sort_by = 'username';
            }

            if (this.selectedFollowerFilter === 'All') {
                this.fetchData();
            }

            this.query.push();

            this.fetchUsers(this.selectedFollowerFilter);
        },
        handleQueryUpdate() {
            if (this.selectedFollowerFilter === 'All') {
                this.fetchData();
            } else {
                this.fetchUsers(this.selectedFollowerFilter);
            }
            this.query.push();
            if (this.query.save_search) {
                localStorage.iotmSearch = location.search;
            }
        },
        updatePage(number) {
            this.pagination.current_page = number;
            this.query.page = number;
            this.handleQueryUpdate();
        },
        toggle(key) {
            if (this.toggled.includes(key)) {
                this.clearToggled();
            } else {
                this.clearToggled();
                this.toggled.push(key);
            }
        },
        clearToggled() {
            this.toggled = [];
        },
        isToggled(key) {
            if (this.toggled.includes(key)) {
                return true;
            }
            return false;
        },
        aggregate(val) {
            if (this.aggregated.includes(val)) {
                const index = this.aggregated.indexOf(val);
                this.aggregated.splice(index, 1);
            } else {
                this.aggregated.push(val);
            }
        },
    },
    watch: {
        approvedAggregate: function () {
            if (this.approvedAgregate === true) {
                window.setInterval(
                    function () {
                        this.approvedAggregate = false;
                    }.bind(this),
                    3000
                );
            }
        },
        approvedUser: function () {
            if (this.approvedUser === true) {
                window.setInterval(
                    function () {
                        this.approvedUser = false;
                    }.bind(this),
                    3000
                );
            }
        },
        blockedUser: function () {
            if (this.blockedUser === true) {
                window.setInterval(
                    function () {
                        this.blockedUser = false;
                    }.bind(this),
                    3000
                );
            }
        },
        blockedAggregate: function () {
            if (this.blockedAggregate === true) {
                window.setInterval(
                    function () {
                        this.blockedAggregate = false;
                    }.bind(this),
                    3000
                );
            }
        },
    },
    computed: {
        filterButton() {
            return {
                'fa fa-sort-alpha-desc': this.userFilter === 'Alphabetic' && this.sortDesc === true,
                'fa fa-sort-alpha-asc': this.userFilter === 'Alphabetic' && this.sortDesc !== true,
                'fa fa-sort-numeric-desc': this.userFilter === 'Date Followed' && this.sortDesc === true,
                'fa fa-sort-numeric-asc': this.userFilter === 'Date Followed' && this.sortDesc !== true,
            };
        },
    },
};
</script>
