<template>
    <div class="card mb-3">
        <div v-if="form">
            <div class="card-header d-flex justify-content-between">
                <h4 class="mb-0">
                    QuickPitch<span class="d-none d-md-inline">: The Booking Time Saver</span>
                </h4>
                <button @click="showTips = 1" class="btn btn-sm btn-outline-primary">
                    <i class="fa fa-question-circle pr-1"></i>Tips
                </button>
            </div>

            <quickpitch-tips v-model="showTips"></quickpitch-tips>

            <div class="card-body">
                <!-- If already submitted or sent -->
                <div v-if="form.denied" class="alert alert-danger">
                    Denied by IOTM on {{ form.date_denied | fromUTC("LLL") }}
                </div>
                <div v-else-if="form.approved" class="alert alert-success">
                    Approved and mailed by IOTM on
                    {{ form.date_sent | fromUTC("LLL") }}
                </div>
                <div v-else-if="form.date_submitted" class="alert alert-info">
                    Submitted on {{ form.date_submitted | fromUTC("LLL") }}
                </div>

                <span class="mb-3 d-block">
                    <p class="font-weight-bold">Email Subject:</p>
                    <span v-html="form.subject" class="trix-content"></span>
                </span>

                <p class="font-weight-bold">Message:</p>
                <div>
                    <span class="d-inline-block trix-content border-grey" v-html="form.body"></span>
                </div>

                <!--Venues-->
                <div class="row mx-0 mb-3">
                    <div class="mt-2 col-sm-6">
                        <b>Venues</b>
                        <li v-for="v in form.good_venues.data">
                            <a :href="venueUrl(v)">
                                {{ v.title }}
                            </a>
                        </li>
                    </div>
                    <div class="mt-2 col-sm-6">
                        <b>Excluded Venues</b>
                        <li v-for="v in excluded">
                            <a :href="venueUrl(v)">
                                {{ v.title }}
                            </a>
                        </li>
                    </div>
                </div>

                <!--Send At Date-->
                <div v-if="form.send_at" class="form-group mt-2">
                    <b for="total">Send On:</b>
                    <span>
                        {{ send_at }}
                        <span v-if="timezone">{{
                            timezone.toUpperCase()
                        }}</span>
                    </span>
                </div>

                <!-- error no card -->
                <div class="mt-4 form-group" v-if="noCardError">
                    <span class="text-danger">
                        {{ noCardError }}
                    </span>
                    <a class="btn btn-sm btn-secondary" href="/subscriptions">
                        Add Card
                    </a>
                </div>

                <!-- error no content -->
                <div class="text-danger mt-4 form-group" v-if="form.body === '' || form.subject === ''">
                    <p>You must enter a subject and body</p>
                </div>

                <div v-if="!form.date_submitted" class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input @change="clearErrors" type="checkbox" v-model="form.terms" class="form-check-input" />
                            I have read and agreed to the IndieOnTheMove.com
                        </label>
                        <a href="/terms-and-conditions" target="_blank" class="terms-link">Terms and Conditions.</a>
                        <p class="text-danger form-control-feedback" v-if="form.errors.has('terms')">
                            You must agree to terms before submitting a
                            QuickPitch.
                        </p>
                    </div>
                </div>

                <div class="form-group" v-if="!form.date_submitted" :class="{ 'has-danger': form.errors.get('save_by') }">
                    <label for="save_by">Save QuickPitch by:</label>
                    <select @change="clearErrors" v-model="form.save_by" class="form-control" id="save_by" :class="{
                            'form-control-danger': form.errors.get('save_by'),
                        }">
                        <option value="name">Save QuickPitch by Name</option>
                        <option value="subject">
                            Save QuickPitch by Subject
                        </option>
                    </select>
                    <span class="form-control-feedback" v-if="form.errors.has('save_by')"
                        v-text="form.errors.get('save_by')"></span>
                </div>

                <div class="form-group row" v-if="!form.date_submitted && form.save_by === 'name'"
                    :class="{ 'has-danger': form.errors.get('name') }">
                    <div class="col-12">
                        <label for="name">
                            <p>Name *</p>
                        </label>
                        <input type="text" name="name" class="form-control" @change="clearErrors" :class="{
                                'form-control-danger': form.errors.get('name'),
                            }" v-model="form.name" />
                        <span class="form-control-feedback" v-if="form.errors.has('name')"
                            v-text="form.errors.get('name')"></span>
                    </div>
                </div>

                <div v-if="form.denied || form.approved" class="d-flex justify-content-between">
                    <a class="btn btn-secondary" href="/venues">
                        Return to Venues
                    </a>
                    <a class="btn btn-primary" :href="cloneUrl"> Resubmit </a>
                </div>

                <a v-else-if="form.date_submitted" class="btn btn-secondary" href="/venues">
                    Return to Venues
                </a>

                <!-- Actions -->
                <div v-if="!form.date_submitted" class="d-flex justify-content-end">
                    <a href="/venues" class="btn mr-2 btn-secondary w-100">
                        <div v-if="!this.form.date_submitted">
                            <span class="d-none d-sm-inline">Add </span>Venues
                        </div>
                        <div v-else>
                            <span class="d-none d-sm-inline">Back to </span>Venues
                        </div>
                    </a>
                    <a v-if="!form.date_submitted" class="mr-2 btn btn-secondary w-100"
                        :href="'/quickpitch/' + uuid + '/edit'">
                        Edit
                    </a>
                    <btn-state :disabled="!this.form.terms" @click="submitQuickpitch" :state="this.form.state"
                        btnClass="mt-0 btn-primary btn-block">
                        Submit<span class="d-none d-sm-inline">
                            For Approval</span>
                    </btn-state>
                </div>

                <div v-show="quickpitchFetchFailed" class="alert alert-danger text-xs-center">
                    QuickPitch not found
                </div>
            </div>
        </div>

        <!-- Loading -->
        <div v-else class="container">
            <loading style="height: 60vh"></loading>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import Query from "../../query/query.js";
import Form from "../../form/form.js";
import btnState from "../../components/btnState.vue";
import venues from "../../components/quickpitch/venues.vue";
import loading from "../../components/loading.vue";
import quickpitchTips from "../../components/quickpitch/quickpitchTips.vue";

export default {
    name: "preview-quickpitch",
    props: {
        timezones: {
            required: true,
            type: Object,
        },
        uuid: {
            required: true,
            type: String,
        },
    },
    components: {
        venues,
        loading,
        quickpitchTips,
        btnState,
    },

    data() {
        return {
            query: new Query({
                include:
                    "venues,good_venues,bad_venues,recent_venues,blocked_venues",
            }),
            timezone: null,
            send_at: null,
            showTips: false,
            quickpitchFetchFailed: false,
            form: false,
            noCardError: false,
        };
    },

    created() {
        this.fetchQuickpitch(this.uuid);
    },

    computed: {
        cloneUrl() {
            return `/quickpitch/${this.uuid}/clone`;
        },
        excluded() {
            let excluded = this.form.bad_venues.data;

            if (this.form.add_recent) {
                return excluded;
            }

            excluded.concat(this.form.recent_venues.data);

            if (this.form.add_blocked) {
                return excluded;
            }

            return excluded.concat(this.form.blocked_venues.data);
        },

        fromEmail() {
            return window.User.email;
        },
    },

    methods: {
        venueUrl(venue) {
            return "/venues/" + venue.slug;
        },
        fetchQuickpitch() {
            axios
                .get(
                    "/api/quickpitch/" + this.uuid + "?" + this.query.toString()
                )
                .then(this.parseQuickpitchResponse)
                .catch((error) => {
                    this.quickpitchFetchFailed = error.data;
                });
        },

        submitQuickpitch() {
            this.form.submitted = true;
            this.form
                .put("/api/quickpitch/" + this.uuid)
                .then(this.fetchQuickpitch)
                .catch((error) => {
                    if (error.status === 403) {
                        this.noCardError =
                            "Please add a credit card to pay for QuickPitch.";
                    }
                });
        },

        parseQuickpitchResponse(response) {
            const qp = response.data.data;
            const oldState = this.form.state;
            this.form = new Form(
                Object.assign({}, qp, {
                    terms: false,
                    save_by: qp.save_by ?? "name",
                    name: qp.name,
                    venues: qp.venues.data.map((i) => i.uuid),
                })
            );
            this.form.state = oldState;
            new Promise((resolve) => {
                var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                var timezone = Object.entries(this.timezones).find((zone) => {
                    return zone.find((z) => z.timezone == tz);
                });
                if (!timezone) {
                    var timezone = Object.entries(this.timezones).find(
                        (zone) => {
                            return zone.find(
                                (z) => z.timezone === "America/New_York"
                            );
                        }
                    );
                }
                resolve(timezone);
            }).then((timezone) => {
                var tz = timezone[1].timezone;
                this.timezone = timezone[0];
                this.send_at = moment.utc(qp.send_at).tz(tz).format("LLL");
            });
        },

        toggleClass(bool) {
            return {
                fa: true,
                "fa-plus": !bool,
                "fa-minus": bool,
            };
        },

        clearErrors() {
            this.form.clearErrors();
        },
    },
};
</script>

<style scoped>
.trix-content {
    overflow-y: hidden;
    max-height: none;
}

a.terms-link {
    text-decoration: underline;
    color: dodgerblue;
    cursor: pointer;
}
</style>
