<template>
    <div class="card">
        <div class="card-header bg-dark text-light">
            <h4 class="mb-0">{{ filter }} Venues</h4>
        </div>

        <div class="card-body">
            <table v-if="!loading" class="table">
                <tbody class="bg-white">
                    <tr v-for="(venue, index) in items">
                        <td :class="index === 0 ? 'pt-0 border-0 row' : 'row'">
                            <div class="col-auto">
                                <img v-if="venue.img_url" class="img-fluid"
                                    :src="venue.img_url + '?w=100&h=100&fit=crop'" />
                                <img v-else class="music-default-sm img-fluid"
                                    src="../../../../public/img/local_music.jpg" />
                            </div>
                            <div class="col">
                                <h6>
                                    <a class="break-word" :href="'/venues/' + venue.slug">
                                        {{ venue.title | truncate(100, '...') }}
                                    </a>
                                </h6>
                                <span class="d-flex">
                                    <star-rating :readOnly="true" :uuid="venue.uuid" :rating="venue.rating"></star-rating>
                                    <span class="pl-1"> ({{ venue.vote_count }}) </span>
                                </span>
                                <p class="mb-0">
                                    {{ venue | cityState }}
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-info" v-show="!loading && items.length === 0">No {{ filter }} Venues</div>

            <loading v-show="loading"></loading>

            <div class="d-flex justify-content-center">
                <a href="/venues" class="btn btn-outline-primary">All Venues</a>
            </div>
        </div>
    </div>
</template>

<script>
import starRating from '../rating/rating.vue';
import loading from '../loading.vue';

export default {
    props: ['filter'],

    components: { starRating, loading },

    data() {
        return {
            loading: true,
            items: [],
        };
    },

    computed: {
        query() {
            const sort = this.filter === 'Local Popular' ? 'popularity' : 'created_at';
            const query = '?limit=3&sort_by=' + sort + '&sort_order=descending';
            if (this.filter === 'National' || !window.User.postal_code) {
                return query;
            }

            return query + '&zip=' + window.User.postal_code + '&radius=100';
        },
    },

    watch: {
        filter: function () {
            this.fetchItems();
        },
    },

    created() {
        this.fetchItems();
    },

    methods: {
        fetchItems() {
            axios.get('/api/venues' + this.query).then(response => {
                this.pagination = response.data.meta.pagination;
                this.items = response.data.data;
                this.loading = false;
            });
        },
    },
};
</script>
