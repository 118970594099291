<template>
    <div v-if="this.render && this.selectedSize > 0" class="mb-2">
        <div class="card">
            <div class="card-header d-flex flex-row justify-content-between messageHeader">
                <h5 class="mx-auto">
                    {{ selected.subject }}
                </h5>
                <button type="button" class="close btn btn-link" @click="this.closeMessage">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12" id="messageArea" ref="messageBox">
                            <ul class="list-group rounded">
                                <li
                                    class="list-group-item msg-item mt-1 rounded d-flex flex-row"
                                    v-for="(msg, key) in conversation.messages.data"
                                    :class="[
                                        key === 0 ? 'mt-4' : '',
                                        wasSender(msg) ? 'active mr-auto' : 'bg-light ml-auto',
                                    ]"
                                >
                                    <div class="msg-content">
                                        <img
                                            class="rounded-circle mr-1 thumbnail"
                                            v-if="msg.user.data.img_url"
                                            v-bind:src="msg.user.data.img_url + '?w=300&h=300&fit=crop'"
                                        />
                                        <small> {{ msg.created_at | fromUTC('MMMM Do YYYY, h:mm a') }} </small>:
                                        <p v-html="msg.body"></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <form
                                class="form-group-row"
                                @click.prevent
                                @keydown.enter.prevent="onSubmit"
                                @keydown="form.clearErrors($event.target.name)"
                                :class="replyFormClass"
                            >
                                <div class="responseMessages">
                                    <transition name="fade">
                                        <div v-if="this.form.state === 'success'" class="form-control-feedback">
                                            <small> Message Sent </small>
                                        </div>
                                    </transition>
                                    <transition name="fade">
                                        <div v-if="this.form.state === 'error'" class="form-control-feedback">
                                            <small> {{ form.errors.get('body') }} </small>
                                        </div>
                                    </transition>
                                </div>
                                <div class="input-group">
                                    <input
                                        v-model="form.body"
                                        class="form-control"
                                        placeholder="Reply"
                                        style="height: 45px"
                                        rows="2"
                                    />
                                    <btn-state v-on:click="onSubmit" class="btn-primary ml-2" :state="form.state">
                                        <small> Send </small>
                                    </btn-state>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Form from '../../form/form.js';
import btnState from '../../components/btnState.vue';

export default {
    components: { btnState },
    props: {
        selected: {
            type: Object,
            required: false,
        },
    },

    created() {
        this.conversation = this.selected;
        this.selectedSize = this.selected.messages.data.length;
        bus.$on('show', msg => {
            this.$nextTick(() => {
                this.conversation = msg;
                this.selectedSize = _.size(msg);
            });
        });
        bus.$on('deleted', this.hideMessage);
    },

    mounted() {
        if (this.selected.messages.data.length) {
            this.scrollLatest();
        }
    },

    data() {
        return {
            convoHeight: '',
            conversation: [],
            selectedSize: 0,
            form: new Form({
                body: '',
            }),
            render: true,
            user: window.User.display_name,
        };
    },
    computed: {
        replyFormClass: function () {
            return {
                'has-success': this.form.state === 'success',
                'has-danger': this.form.state === 'error',
                '': this.form.state === 'none',
            };
        },
    },
    watch: {
        conversation: function () {
            if (_.has(this.conversation, 'messages')) {
                var msg = this.conversation.messages.data;
                var result = _.forEach(msg, function (value) {
                    const user = value.user.data.name;
                    value.from = user;
                });
                return result;
            }
            this.conversation = result;
        },
    },
    methods: {
        scrollLatest() {
            var height = this.$refs.messageBox.scrollHeight;
            window.$('#messageArea').scrollTop(height);
        },
        showMessage() {
            this.selectedSize = 1;
        },
        closeMessage() {
            bus.$emit('close');
        },
        hideMessage() {
            this.render = false;
            this.selectedSize = 0;
        },
        fetchMessages(uuid) {
            axios.get('/api/conversations/' + uuid).then(response => {
                this.conversation = response.data.data;
                this.$nextTick(() => {
                    this.scrollLatest();
                });
            });
        },
        toggleReadStatus(msg) {
            axios.post('/api/conversations/toggle-read-status/' + msg, {
                toggle_participant: true,
            });
        },
        wasSender(val) {
            if (val.user.data.display_name === this.user) {
                return true;
            }
        },
        onSubmit() {
            this.form
                .post('/api/conversations/' + this.selected.uuid + '/messages', {
                    body: this.form.body,
                })
                .then(response => {
                    this.form.body = '';
                    this.fetchMessages(response.data.data.uuid);
                });
            this.toggleReadStatus(this.selected.uuid);
        },
    },
};
</script>
