<template>
    <div>
        <table v-if="!loading" class="table table-hover bordered">
            <thead class="bg-primary">
                <tr class="small text-white">
                    <th>Classified</th>
                    <th class="cursor-pointer" @click="$emit('sortBy', 'date_expires')">
                        <i :class="sortClass('date_expires')"></i>
                        Expires
                    </th>
                    <th class="d-none d-md-table-cell cursor-pointer" @click="$emit('sortBy', 'created_at')">
                        <i :class="sortClass('created_at')"></i>
                        Created
                    </th>
                    <th v-show="showDistance" class="cursor-pointer" @click="$emit('sortBy', 'distance')">
                        <i :class="sortClass('distance')"></i>
                        Distance
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="classified in classifieds">
                    <td class="row">
                        <div class="col-auto">
                            <img v-if="classified.img_url" class="img-fluid"
                                :src="classified.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="o50 user-default-sm img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word" :href="'/classifieds/' + classified.slug + '/' + classified.uuid">
                                    {{ classified.title | truncate(100, '...') }}
                                </a>
                            </h6>
                            <p class="mb-0">
                                {{ classified | cityState }}
                            </p>
                        </div>
                    </td>
                    <td class="date-cell">{{ classified.date_expires | dateFormat('MM-DD-YY') }}</td>
                    <td class="d-none d-md-table-cell date-cell">
                        {{ classified.created_at | fromUTC('MM-DD-YY') }}
                    </td>
                    <td v-show="showDistance">{{ classified.distance.toFixed(1) }} miles</td>
                </tr>
            </tbody>
        </table>
        <div v-if="classifiedsEmpty" class="card card-body text-center">
            <p class="m-3">No classifieds found.</p>
        </div>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';

export default {
    props: ['loading', 'classifieds', 'query', 'showDistance'],

    name: 'classified-results',

    components: { loading },

    computed: {
        classifiedsEmpty() {
            return !this.loading && !this.classifieds.length;
        },
    },

    methods: {
        sortClass(field) {
            return {
                'd-none': !(this.query.sort_by === field),
                fa: true,
                'fa-sort-amount-asc': this.query.sort_order === 'ascending',
                'fa-sort-amount-desc': this.query.sort_order === 'descending',
            };
        },
    },
};
</script>
