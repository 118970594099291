<template>
    <div>
        <table v-if="!loading" class="table">
            <tbody class="bg-white">
                <tr v-for="(availability, index) in items">
                    <td :class="index === 0 ? 'pt-0 border-0 row' : 'row'">
                        <div class="col-auto">
                            <img v-if="availability.img_url" class="img-fluid"
                                :src="availability.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word"
                                    :href="'/band-availabilities/' + availability.slug + '/' + availability.uuid">
                                    {{ availability.title | truncate(100, '...') }}
                                </a>
                            </h6>
                            <p class="mb-0 break-word">
                                {{ availability.band_name }}
                            </p>
                            <p class="mb-0">
                                {{ availability | cityState }}
                            </p>
                            <p class="mb-0 d-none d-sm-inline" v-if="availability.genres">
                                {{ availability.genres.data | genreString }}
                            </p>
                        </div>
                    </td>
                    <td class="date-cell">{{ availability.date | dateFormat('MM-DD-YY') }}</td>
                </tr>
            </tbody>
        </table>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';

export default {
    props: ['loading', 'items'],

    name: 'availability-results',

    components: { loading },
};
</script>
