<template>
    <div>
        <v-select
            :filterable="false"
            label="username"
            class="user-select"
            v-model="selectedUser"
            @search="onSearch"
            :options="users"
            @input="handleUserUpdate"
            placeholder="Type to search users.."
        >
            <template slot="option" slot-scope="option">
                <span>
                    {{ option.username }}
                    <span class="d-block">
                        {{ option | cityState }}
                    </span>
                </span>
                <span :class="option.icon" v-if="option.img_url">
                    <img class="img-thumbnail" :src="option.img_url + '?w=60&h=60&fit=crop'" />
                </span>
            </template>
        </v-select>
    </div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
    components: {
        vSelect,
    },
    data() {
        return {
            loading: false,
            selectedUser: '',
            users: [],
            cancelToken: axios.CancelToken,
            currentRequest: null,
        };
    },
    methods: {
        onSearch(search, loading) {
            this.fetchUsers(loading, search);
        },
        handleUserUpdate() {
            this.$emit('update', this.selectedUser);
        },

        fetchUsers(loading, query = '') {
            if (this.currentRequest) {
                this.currentRequest.cancel();
            }

            if (query && query.length > 1) {
                this.loading = true;
                this.currentRequest = this.cancelToken.source();
                let url =
                    '/api/users?sort_by=relevance&limit=' + Config.multiselect_search_result_count + '&q=' + query;
                axios
                    .get(url, {
                        cancelToken: this.currentRequest.token,
                    })
                    .then(response => {
                        this.users = response.data.data;
                        this.loading = false;
                        this.currentRequest = null;
                    })
                    .catch(() => {
                        // don't err after cancel
                    });
            }
        },
    },
};
</script>
<style>
.dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.dropdown li:last-child {
    border-bottom: none;
}
.dropdown li a {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.dropdown li a .fa {
    padding-right: 0.5em;
}
</style>
