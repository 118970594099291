<template>
    <div class="genreSelect">
        <input type="hidden" :name="name" v-model="genresString" />

        <!-- .is-error.el-form-item adds a red border -->
        <div class="el-form-item mb-0" :class="{ 'is-error': error }">
            <el-select
                multiple
                :value="value"
                :placeholder="placeholder"
                :size="size"
                clearable
                filterable
                no-match-text="No results"
                ref="input"
                @change="handleChange"
            >
                <el-option v-for="item in options" :key="item.id" :label="item.genre" :value="item.id"> </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isFilter: {
            default: false,
        },

        size: {
            type: String,
            default: '',
        },

        error: {
            type: Boolean,
        },

        name: {
            type: String,
            default: 'genres',
        },

        value: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            options: [],
        };
    },

    created() {
        this.fetchGenres();
    },

    computed: {
        placeholder() {
            return this.isFilter ? 'All Genres' : 'Select Genres';
        },

        genresString() {
            return this.value.reduce((acc, g) => {
                return !acc ? g.toString() : acc + ',' + g;
            }, '');
        },
    },

    methods: {
        handleChange(val) {
            this.$emit('input', val);
            bus.$emit('queryUpdate', val);
        },

        fetchGenres() {
            return axios.get('/api/genres' + (this.isFilter ? '' : '?allgenres=true')).then(response => {
                this.options = response.data.data;
            });
        },
    },
};
</script>
