<template>
    <div>
        <btn-state :state="form.state" @click="add" btnClass="btn btn-sm btn-primary m-1"> QuickPitch </btn-state>
        <deluxe-modal v-model="showModal"></deluxe-modal>
    </div>
</template>

<script>
/* global $ */

import Query from '../../query/query.js';
import BtnState from '../btnState.vue';
import Form from '../../form/form.js';
import deluxeModal from '../deluxeModal.vue';

export default {
    name: 'add-to-quickpitch',

    components: { deluxeModal, BtnState },

    props: {
        venue: {
            type: String,
            required: true,
        },
    },

    computed: {
        quickpitchUuid() {
            return window.User.draft_quickpitch_uuid;
        },
        isDeluxe() {
            return window.User.is_deluxe;
        },
    },

    created() {
        if (this.quickpitchUuid && this.isDeluxe) {
            this.fetchQuickpitch();
        }
    },

    data() {
        return {
            query: new Query({
                include: 'venues',
            }),
            showModal: false,
            form: {},
        };
    },

    methods: {
        fetchQuickpitch() {
            axios
                .get('/api/quickpitch/' + this.quickpitchUuid + '?' + this.query.toString())
                .then(this.parseQuickpitchResponse);
        },

        add() {
            if (!this.quickpitchUuid || !this.isDeluxe) {
                this.showModal = true;
                return;
            }

            this.form.venues = this.form.venues.concat(this.venue);

            this.form.put('/api/quickpitch/' + this.quickpitchUuid).then(() => {
                window.location = '/quickpitch/' + this.quickpitchUuid + '/edit';
            });
        },

        parseQuickpitchResponse(response) {
            const qp = response.data.data;
            const venues = qp.venues.data;
            this.form = new Form(
                Object.assign({}, qp, {
                    venues: venues.map(i => i.uuid),
                })
            );
        },
    },
};
</script>
