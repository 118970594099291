<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <button type="button" class="btn btn-secondary mr-2" @click="(sortDesc = !sortDesc), updateQuery()">
                            <i :class="filterButton"></i>
                        </button>

                        <div class="btn-group d-none d-lg-inline mr-2">
                            <button type="button" data-toggle="dropdown" class="btn btn-secondary dropdown-toggle">
                                {{ userFilter | truncate(10, '...') }}
                            </button>

                            <div class="dropdown-menu">
                                <a @click.prevent="(userFilter = filter), updateQuery()" class="dropdown-item"
                                    v-for="filter in filters" href="#">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>

                        <div class="btn-group d-none d-lg-inline">
                            <button type="button" data-toggle="dropdown" class="btn btn-secondary dropdown-toggle">
                                {{ blockedFilter | truncate(10, '...') }}
                            </button>

                            <div class="dropdown-menu">
                                <a href="#" class="dropdown-item" v-for="filter in blockedFilters"
                                    @click="(blockedFilter = filter), fetchData()">
                                    {{ filter }}
                                </a>
                            </div>
                        </div>

                        <button class="btn btn-outline-primary d-none d-lg-inline ml-lg-2 mr-auto"
                            v-if="blockedFilter === 'All'" v-bind:disabled="aggregated[0] === undefined"
                            @click="unfollowAggregate">
                            Unfollow
                        </button>
                        <button class="btn btn-outline-primary d-none d-lg-inline ml-lg-2 mr-auto"
                            v-if="blockedFilter === 'Blocked QP'" v-bind:disabled="aggregated[0] === undefined"
                            @click="unblockAggregate">
                            Unblock
                        </button>
                        <input @keydown.enter.prevent="fetchData()" @keyup.delete="clearQuery()"
                            @keydown.delete="clearQuery()" v-model="query.q" class="form-control col-xl-6 col-lg-4 mx-2"
                            placeholder="Search for venues..." />
                        <button class="btn btn-primary" @click.prevent="fetchData()">Search</button>
                    </div>

                    <div class="card-body">
                        <span class="d-flex flex-row justify-content-center mb-3 d-md-none">
                            <button type="button" @click="unfollowAggregate" v-bind:disabled="aggregated[0] === undefined"
                                v-if="blockedFilter === 'All'" class="btn btn-outline-primary mr-auto mx-lg-1">
                                Unfollow
                            </button>
                            <button type="button" @click="unblockAggregate" v-bind:disabled="aggregated[0] === undefined"
                                v-if="blockedFilter === 'Blocked QP'" class="btn btn-outline-primary mr-auto mx-lg-1">
                                Unblock
                            </button>
                            <div class="btn-group">
                                <button type="button" data-toggle="dropdown" class="btn btn-secondary dropdown-toggle">
                                    {{ userFilter | truncate(10, '...') }}
                                </button>
                                <button type="button" class="btn btn-secondary ml-2"
                                    @click="(sortDesc = !sortDesc), updateQuery()">
                                    <i :class="filterButton"></i>
                                </button>
                                <div class="dropdown-menu">
                                    <a @click.prevent="(userFilter = filter), updateQuery()" class="dropdown-item"
                                        v-for="filter in filters" href="#">
                                        {{ filter }}
                                    </a>
                                </div>
                            </div>
                        </span>
                        <transition name="fade">
                            <div class="alert alert-success" v-if="unfollowSuccess">
                                <strong>Success</strong> venues unfollowed
                            </div>
                            <div class="alert alert-success" v-if="unblockSuccess">
                                <strong>Success</strong> venues unblocked
                            </div>
                        </transition>
                        <ul class="list-group" v-if="options.length">
                            <li class="list-group-item d-flex flex-row justify-content-start">
                                <small class="ml-1"> Title </small>
                                <small class="ml-auto"> Date Followed </small>
                            </li>
                            <li class="list-group-item d-flex flex-row justify-content-start"
                                :class="[isToggled(index) ? 'bg-primary' : '']" v-for="(option, index) in options"
                                @click="toggle(index)">
                                <span class="mr-auto">
                                    <input type="checkbox" :checked="aggregated.includes(option)"
                                        @click="aggregate(option)" />
                                    <span class="ml-2" :class="[isToggled(index) ? 'text-white' : '']">
                                        {{ option.title | truncate(20, '...') }}
                                    </span>
                                    <img v-if="option.img_url" class="navbar-avatar img-circle ml-1 d-none d-md-block"
                                        :src="option.img_url + '?w=300&h=300&fit=crop'" />
                                    <img v-else src="../../../../../public/img/local_music.jpg"
                                        class="navbar-avatar img-circle ml-1 d-none d-md-block" />
                                </span>
                                <span v-if="isToggled(index)" class="mr-auto">
                                    <span class="d-none d-md-inline">
                                        <small class="text-white"> Profile </small>
                                        <button class="btn btn-link rounded-circle bg-white listButton"
                                            @click="visitProfile(option)">
                                            <i class="fa fa-user-o"></i>
                                        </button>
                                    </span>
                                    <template v-if="blockedFilter === 'All'">
                                        <small class="text-white"> Unfollow </small>
                                        <button class="btn btn-link rounded-circle bg-white listButton"
                                            @click="unfollowVenue(option)">
                                            <i class="fa fa-times-circle"></i>
                                        </button>
                                    </template>
                                    <template v-if="blockedFilter === 'Blocked QP'">
                                        <small class="text-white"> Unblock Quickpitch </small>
                                        <button class="btn btn-link rounded-circle bg-white listButton"
                                            @click="unblockVenue(option)">
                                            <i class="fa fa-times-circle"></i>
                                        </button>
                                    </template>
                                </span>

                                <span v-if="option.date_followed">
                                    <small class="ml-auto" :class="[isToggled(index) ? 'text-white' : '']">
                                        <span> Since: {{ option.date_followed.data.created_at }} </span>
                                    </small>
                                </span>
                            </li>
                        </ul>
                        <div v-else-if="this.query.q && !this.options.length">
                            <div class="mb-0 alert alert-info">No venues found.</div>
                        </div>
                        <div v-else-if="!this.options.length">
                            <div class="mb-0 alert alert-info">Not following any venues.</div>
                        </div>

                        <pagination class="mt-2" v-if="options.length" :pagination="pagination" :loading="isLoading"
                            @pageChange="updatePage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from '../../../query/query.js';
import pagination from '../../../components/pagination.vue';
import axios from 'axios';

export default {
    components: {
        Query,
        pagination,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            allSelected: false,
            query: new Query({
                q: '',
                page: '',
                sort_by: 'title',
                follow_type: 'venues',
                sort_order: 'descending',
                include: 'date_followed',
            }),
            aggregated: [],
            isLoading: false,
            options: [],
            filters: ['Alphabetic', 'Date Followed'],
            blockedFilters: ['All', 'Blocked QP'],
            toggled: [],
            sortDesc: false,
            showControls: false,
            unfollowSuccess: false,
            unblockSuccess: false,
            pagination: {
                current_page: 1,
            },
            userFilter: 'Alphabetic',
            blockedFilter: 'All',
        };
    },
    methods: {
        visitProfile(option) {
            window.location.assign('/venues/' + option.slug);
        },
        sendMessage() {
            window.location.assign('/messages');
        },
        toggle(key) {
            if (this.toggled.includes(key)) {
                this.clearToggled();
            } else {
                this.clearToggled();
                this.toggled.push(key);
            }
        },
        clearToggled() {
            this.toggled = [];
        },
        isToggled(key) {
            if (this.toggled.includes(key)) {
                return true;
            }
            return false;
        },
        aggregateAll() {
            if (this.allSelected) {
                this.aggregated = [];
                this.allSelected = false;
            } else {
                this.options.forEach(function (element) {
                    this.aggregated.push(element);
                }, this);
                this.allSelected = true;
            }
        },
        clearQuery() {
            if (this.query.q === '' || this.query.q === ' ') {
                this.fetchData();
            }
        },
        unfollowAggregate() {
            this.aggregated.forEach(function (element) {
                axios.delete('/api/venues/' + element.uuid + '/unfollow').then(() => {
                    this.unfollowSuccess = true;
                    this.fetchData();
                });
            }, this);
        },
        unblockAggregate() {
            this.aggregated.forEach(function (element) {
                axios.delete('/api/venues/' + element.uuid + '/unblock')
                    .then(() => {
                        this.unblockSuccess = true;
                        this.fetchData();
                    });
            }, this);
        },
        unfollowVenue(option) {
            axios.delete('/api/venues/' + option.uuid + '/unfollow').then(response => {
                this.unfollowSuccess = true;
                this.fetchData();
            });
            this.$nextTick(() => {
                this.clearToggled();
            });
        },
        unblockVenue(option) {
            axios.delete('/api/venues/' + option.uuid + '/unblock')
                .then(response => {
                    this.unblockSuccess = true;
                    this.fetchData();
                });
            this.$nextTick(() => {
                this.clearToggled();
            })
        },
        updateQuery() {
            if (this.sortDesc === true) {
                this.query.sort_order = 'descending';
            } else {
                this.query.sort_order = 'ascending';
            }
            if (this.userFilter === 'Alphabetic') {
                this.query.sort_by = 'title';
            } else if (this.userFilter === 'Date Followed') {
                this.query.sort_by = 'date_followed';
            }
            this.fetchData();
        },
        aggregate(val) {
            if (this.aggregated.includes(val)) {
                const index = this.aggregated.indexOf(val);
                this.aggregated.splice(index, 1);
            } else {
                this.aggregated.push(val);
            }
        },
        fetchData() {
            switch (this.blockedFilter) {
                case 'All':
                    axios
                        .get('/api/users/' + window.User.uuid + '/following-venues/?' + this.query.toString())
                        .then(response => {
                            this.options = response.data.data;
                            this.pagination = response.data.meta.pagination;
                        });
                    break;
                case 'Blocked QP':
                    axios
                        .get('/api/venues/' + window.User.uuid + '/blocked-venues/?' + this.query.toString())
                        .then(response => {
                            this.options = response.data.data;
                            this.pagination = response.data.meta.pagination;
                        });
                    break;
            }
        },
        handleQueryUpdate() {
            this.query.push();
            this.fetchData();
            if (this.query.save_search) {
                localStorage.iotmSearch = location.search;
            }
        },
        updatePage(number) {
            this.pagination.current_page = number;
            this.query.page = number;
            this.handleQueryUpdate();
        },
    },
    watch: {
        unfollowSuccess: function () {
            if (this.unfollowSuccess) {
                window.setInterval(
                    function () {
                        this.unfollowSuccess = false;
                    }.bind(this),
                    3000
                );
            }
        },
        unblockSuccess: function () {
            if (this.unblockSuccess) {
                window.setInterval(
                    function () {
                        this.unblockSuccess = false;
                    }.bind(this),
                    3000
                );
            }
        },
    },
    computed: {
        filterButton() {
            return {
                'fa fa-sort-alpha-desc': this.userFilter === 'Alphabetic' && this.sortDesc === true,
                'fa fa-sort-alpha-asc': this.userFilter === 'Alphabetic' && this.sortDesc !== true,
                'fa fa-sort-numeric-desc': this.userFilter === 'Date Followed' && this.sortDesc === true,
                'fa fa-sort-numeric-asc': this.userFilter === 'Date Followed' && this.sortDesc !== true,
            };
        },
    },
};
</script>
